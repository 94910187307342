import { useParams } from "react-router-dom";
import {
  Typography,
  Result,
  Image,
  Divider,
  Form,
  notification,
  Avatar,
  Carousel,
  Button,
  Skeleton,
  Tag,
  Space,
  Alert,
} from "antd";

import { useEffect, useLayoutEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import { HContent } from "../../../components/common/HContent";

import { timeConverter } from "../../../utils/timeConverter";
import { ReviewDetail, Comment } from "./type";
import { urlFilter } from "../../../utils/paramsFilter";

export const DetailPage = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [comments, setComments] = useState<Comment[]>([]);
  const [data, setData] = useState<ReviewDetail>();
  const [error, setError] = useState<AxiosError | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [disable, setDisable] = useState(false);

  const onClickNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<ReviewDetail>(`/seller/post/${id!}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error.response);
        if (axios.isAxiosError(error)) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    LPPAXIOS.get(
      urlFilter({
        url: `/seller/post/${id}/comment`,
        page: currentPage,
        perPage: 25,
      })
    )
      .then((resolve) => {
        console.log(resolve.data.data);
        let current = [...comments, ...resolve.data.data];
        if (current.length === resolve.data.total) {
          setDisable(true);
        }
        setComments((prev) => [...prev, ...resolve.data.data]);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
        api.error({
          message: "댓글 조회 실패.",
        });
      });
  }, [currentPage, id]);

  if (error?.response?.data) {
    const errorMessage = (error.response.data as any).message;
    return <Result status={404} title={errorMessage} />;
  }

  return (
    <>
      {contextHolder}
      <HContent>
        {/* 리뷰 보여줄 곳 */}
        {loading ? (
          <Skeleton active={loading} />
        ) : (
          <>
            <Typography>
              <Avatar
                src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.profile}`}
                size="large"
              />
              <Typography.Text>{data?.nickName}</Typography.Text>
              <Tag>{data?.role}</Tag>
            </Typography>
            <Typography>
              <Typography.Text>
                작성일: {timeConverter(data?.createdAt ?? "")}
              </Typography.Text>
            </Typography>
            <Typography>
              <Typography.Text>조회수: {data?.view}</Typography.Text>
            </Typography>
            {data?.isShow === 0 && (
              <Alert
                message="삭제된 게시글"
                description="해당 게시글은 삭제된 게시글입니다."
                type="error"
                showIcon
                closable
              />
            )}
            <Divider
              orientation="center"
              orientationMargin={3}
              children="리뷰상품"
            />
            {data?.productData && (
              <Carousel>
                {data.productData.map((product, index) => (
                  <div
                    key={`preview-${index}`}
                    className="flex items-start justify-start w-full gap-1 p-2 rounded-lg bg-slate-500"
                  >
                    <Image
                      preview={false}
                      width={120}
                      height={120}
                      style={{ objectFit: "cover", borderRadius: 4 }}
                      src={`${process.env.REACT_APP_IMG_ENDPOINT}/${product.productRepresentativeImage}`}
                    />

                    <div>
                      <p className="w-[270px] font-semibold text-white truncate">
                        {product.productName}
                      </p>
                      <span className="text-white line-through ">
                        {product.originPrice}원
                      </span>
                      <p className="text-red-300">
                        {product.originPrice - product.discountPrice}원
                      </p>
                      {product.isDiscounted === 1 && (
                        <span className="text-lime-300">
                          {(product.discountPrice / product.originPrice) * 100}%
                          할인
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </Carousel>
            )}

            {/* 리뷰 댓글 보여줄 곳 */}
            <Divider
              orientation="center"
              orientationMargin={3}
              children="리뷰"
            />
            {data?.video.length === 0 ? (
              data.photos.map((item, index) => (
                <Image
                  key={`data-ddd-${index}`}
                  preview={false}
                  loading="lazy"
                  width={300}
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item}`}
                />
              ))
            ) : (
              <video
                poster={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.thumbnail}`}
                src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.video}`}
                width="300"
                autoPlay
                controls
                controlsList="nodownload"
              />
            )}
            <div
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {data?.content.split(`\\n`).map((text, index) => {
                return <p key={`review-text-${index}`}>{text}</p>;
              })}
            </div>
            <Divider>댓글</Divider>

            <Typography>
              {comments.map((comment, index) => {
                const paddingLeft = `${comment.level * 40}px`;
                return (
                  <div
                    key={`${comment.id}-${index}`}
                    className={`flex items-start justify-start gap-2 mb-2 w-full `}
                    style={{ paddingLeft }}
                  >
                    <Avatar
                      src={`${process.env.REACT_APP_IMG_ENDPOINT}/${comment.profile}`}
                      size="large"
                    />
                    <div className="flex flex-col items-start justify-start">
                      <div className="text-slate-400">
                        {comment.nickName === ""
                          ? "알수없음"
                          : comment.nickName}{" "}
                        <Tag>{comment.role}</Tag>
                      </div>
                      <div className="text-md">
                        {comment.comment.split("\n").map((item, index) => {
                          return <p key={`comment-${index}`}>{item}</p>;
                        })}
                      </div>
                      {comment.isShow === 0 && (
                        <Tag color="red">삭제된 댓글입니다.</Tag>
                      )}
                      <div className="text-slate-400">
                        {timeConverter(comment.createdAt)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Typography>
            <Button disabled={disable} onClick={onClickNext}>
              댓글 불러오기({comments.length}/{total})
            </Button>
          </>
        )}
      </HContent>
    </>
  );
};
