interface UrlFilterOptions {
  url: string;
  page: number | string;
  perPage: number | string;
  search?: string | null;
  sort?: string | null;
  order?: string | null;
  type?: string | null;
  status?: string | null;
}
interface NavFilterOptions {
  search?: string | null;
  sort?: string | null;
  order?: string | null;
  type?: string | null;
  status?: string | null;
}

export const urlFilter = ({
  url,
  page,
  perPage,
  search = null,
  sort = null,
  order = null,
  type = null,
  status = null,
}: UrlFilterOptions) => {
  let _url = url;
  _url += `?page=${page}`;
  _url += `&perPage=${perPage}`;
  _url += search === null ? "" : `&search=${search}`;
  _url += sort === null ? "" : `&sort=${sort}`;
  _url += order === null ? "" : `&order=${order}`;
  _url += type === null ? "" : `&type=${type}`;
  _url += status === null ? "" : `&status=${status}`;
  return _url;
};

export const navFilter = ({
  search = null,
  sort = null,
  order = null,
  type = null,
  status = null,
}: NavFilterOptions) => {
  let _url = "";
  _url += search === null ? "" : `&search=${search}`;
  _url += sort === null ? "" : `&sort=${sort}`;
  _url += order === null ? "" : `&order=${order}`;
  _url += type === null ? "" : `&type=${type}`;
  _url += status === null ? "" : `&status=${status}`;
  return _url;
};
