import {
  Button,
  Form,
  Input,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { Content } from "antd/es/layout/layout";
import useNotification from "antd/es/notification/useNotification";
import { useEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import CardWidget from "../../../components/shared/CustomCardWidget";
import FormItem from "../../../components/shared/CustomFormItem";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { PlusOutlined } from "@ant-design/icons";
import { handleUploadCompMultiFiles } from "../../../utils/uploadImgToS3";
import AntdImgCrop from "antd-img-crop";
import Dragger from "antd/es/upload/Dragger";

import { InboxOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 24 },
};
const props: UploadProps = {
  name: "file",
  multiple: false,
  maxCount: 1,
  beforeUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";

    const isMp4 = file.type === "video/mp4";
    if (!isJpgOrPng && !isMp4) {
      message.error("이미지 및 PDF 파일만 업로드 가능합니다.");
      return Upload.LIST_IGNORE; // 이 파일을 업로드 목록에서 제외합니다.
    }

    return true; // 파일 형식이 조건에 맞으면 업로드를 계속 진행합니다.
  },
  customRequest({ file, onSuccess }) {
    const res = "Ok";
    onSuccess!(res);
  },
};
export default function BrandPage() {
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  // 통신판매증명서  sellerEC
  // 사업자증명서  sellerFile
  // 사업자등록번호  sellerRegistration

  const [sellerECFile, setSellerECFile] = useState<UploadFile[]>([]);
  const [sellerFile, setSellerFile] = useState<UploadFile[]>([]);
  const validateFiles = (rule: any, value: any) => {
    if (sellerFile.length === 0) {
      return Promise.reject(new Error("사업자등록증 등록은 필수입니다."));
    }
    return Promise.resolve();
  };
  const validateECFiles = (rule: any, value: any) => {
    if (sellerECFile.length === 0) {
      return Promise.reject(new Error("통신판매 신고증 등록은 필수입니다."));
    }
    return Promise.resolve();
  };

  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const onClickChangeStoreName = async (value: any) => {
    try {
      setLoading(true);
      const brandBanner =
        (await handleUploadCompMultiFiles(uploadFileList, "profile")) ?? [];
      const sellerFileResult =
        (await handleUploadCompMultiFiles(sellerFile, "sellerRegistration")) ??
        [];

      const sellerECFileResult =
        (await handleUploadCompMultiFiles(sellerECFile, "sellerEC")) ?? [];

      LPPAXIOS.put(`/seller/seller/brand`, {
        brandName: value.brandName,
        brandBanner: brandBanner,
        sellerRegistration: value.sellerRegistration,
        sellerFile: sellerFileResult[0],
        sellerEC: sellerECFileResult[0],
      }).then((resolve) => {
        api.success({
          message: "스토어정보가 변경되었습니다.",
        });
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }

    console.log(value);
  };

  const startEdit = (imageList: string[], stateFunc: Function) => {
    const imageFileList = imageList;
    const arr = [];
    for (let i = 0; i < imageFileList.length; i++) {
      const previewFile: UploadFile = {
        uid: `done file ${i}`,
        name: imageFileList[i],
        status: "done",
        url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
      };
      arr.push(previewFile);
    }
    stateFunc(arr);
  };

  const onChangeSellerFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setSellerFile(newupdatedFileList);
  };
  const onChangeSellerECFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setSellerECFile(newupdatedFileList);
  };
  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<{
      brandName: string;
      brandBanner: string[];
      sellerFile: string;
      sellerRegistration: string;
      sellerEC: string;
    }>(`/seller/seller/brand`)
      .then((resolve) => {
        form.setFieldsValue({ ...resolve.data });
        startEdit(resolve.data.brandBanner, setUploadFileList);
        startEdit([resolve.data.sellerFile], setSellerFile);
        startEdit([resolve.data.sellerEC], setSellerECFile);
      })
      .catch((error) => {
        api.error({
          message: "스토어 정보를 가져오지 못했습니다.",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Content className="">
        <Form {...layout} form={form} onFinish={onClickChangeStoreName}>
          <CardWidget title={<Typography.Text>스토어 정보</Typography.Text>}>
            <Form.Item label="브랜드 네임" name="brandName">
              <Input className="rounded-none" />
            </Form.Item>
            <Form.Item
              label="스토어 브랜드 배너"
              name="brandBanner"
              extra="권장 크기: 360 x 160, 최대 5개 등록이 가능합니다."
            >
              <AntdImgCrop aspect={36 / 16}>
                <Upload
                  fileList={uploadFileList}
                  accept="image/*"
                  listType="picture-card"
                  showUploadList={{ showPreviewIcon: false }}
                  customRequest={({ onSuccess }) => {
                    const res = "Ok";
                    setTimeout(() => {
                      onSuccess!(res);
                    }, 600);
                  }}
                  onChange={({ fileList }) => {
                    setUploadFileList(fileList);
                  }}
                >
                  {uploadFileList.length >= 5 ? null : (
                    <div>
                      <PlusOutlined rev={undefined} />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </AntdImgCrop>
            </Form.Item>
            <Form.Item required label="사업자번호" name="sellerRegistration">
              <Input className="rounded-none" />
            </Form.Item>
            <Form.Item
              required
              label="사업자증명서"
              name="sellerFile"
              rules={[{ validator: validateFiles }]}
            >
              <Dragger
                maxCount={1}
                multiple={false}
                fileList={sellerFile}
                onChange={onChangeSellerFile}
                {...props}
                accept="image/jpg,image/png,image/jpeg,application/pdf"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined rev={undefined} />
                </p>
                <p className="ant-upload-text">
                  파일 형식은 jpg, png, pdf만 가능합니다.
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item
              required
              label="통신판매신고증"
              name="sellerFile"
              rules={[{ validator: validateECFiles }]}
            >
              <Dragger
                maxCount={1}
                multiple={false}
                fileList={sellerECFile}
                onChange={onChangeSellerECFile}
                {...props}
                accept="image/jpg,image/png,image/jpeg,application/pdf"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined rev={undefined} />
                </p>
                <p className="ant-upload-text">
                  파일 형식은 jpg, png, pdf만 가능합니다.
                </p>
              </Dragger>
            </Form.Item>
          </CardWidget>
          <div className="flex justify-center w-full p-2">
            <Button type="primary" htmlType="submit" className="rounded-none">
              스토어 정보 수정
            </Button>
          </div>
        </Form>
      </Content>
    </>
  );
}
