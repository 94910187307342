import Divider from "antd/es/divider";
import Form from "antd/es/form";
import { ReactNode } from "react";

const FormItem = ({
  name,
  label,
  isrequired = false,
  children,
  extra,
  isLast = false,
}: {
  name?: string;
  label?: string;
  isrequired?: boolean;
  children: ReactNode;
  extra?: ReactNode;
  isLast?: boolean;
}) => {
  return (
    <>
      <Form.Item
        required={false}
        extra={extra}
        rules={[
          {
            required: isrequired,
            message: `${label} 설정은 필수입니다.`,
          },
        ]}
        name={name}
        label={
          label && (
            <div className="w-[120px] flex justify-start">
              <span className="text-left">{label}</span>
            </div>
          )
        }
      >
        {children}
      </Form.Item>
      {!isLast && <Divider />}
    </>
  );
};
export default FormItem;
