import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 64 }} spin rev={undefined} />
);
export const LoadingSpin = ({ loading }: { loading: boolean }) => {
  return (
    <div
      className={
        loading
          ? "w-screen h-screen bg-white/50 fixed z-10"
          : "hidden w-screen h-screen bg-white/50 fixed z-10"
      }
    >
      <Spin
        className="relative left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
        spinning={loading}
        indicator={antIcon}
        size="large"
      ></Spin>
    </div>
  );
};
