import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  Typography,
  Upload,
  theme,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";

import { ArrowDownOutlined } from "@ant-design/icons";

import { useLayoutEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { useNavigate, useParams } from "react-router-dom";

import NumberInput from "../../../components/shared/CustomInput";
import FormItem from "../../../components/shared/CustomFormItem";
import CardWidget from "../../../components/shared/CustomCardWidget";
import { Category, HashTag, Item, Product } from "./types";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { RuleObject } from "rc-field-form/lib/interface";
import { sizeOptionCheckboxList } from "../../../utils/constant";
import { handleUploadCompMultiFiles } from "../../../utils/uploadImgToS3";
import { LPPUrls } from "../../../LPPUrls";

import { DraggableUploadListItem } from "./DraggableUploadItem";
import { DebounceSelect, fetchUserList } from "./SearchHashTag";
import EditableCell from "./EditableCell";

export const UpdatePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category[]>([]);
  const [currentLgCategory, setCurrentLgCategory] = useState<Category | null>(
    null
  );
  const [currentMdCategory, setCurrentMdCategory] = useState<Category | null>(
    null
  );
  const [currentSmCategory, setCurrentSmCategory] = useState<Category | null>(
    null
  );

  const [hashTagList, setHashTagList] = useState<HashTag[]>([]);

  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [additionalImgList, setAdditionalImgList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const [isDiscounted, setIsDiscounted] = useState(false);

  const [optioned, setOptioned] = useState(true);
  const [usedOptioned, setUsedOptioned] = useState(false);

  const [colorOptions, setColorOptions] = useState<string[]>([]);
  const [sizeOptions, setSizeOptions] = useState<any[]>([]);

  const [isFreeDelivery, setIsFreeDelivery] = useState(true); // 무료배송인지 유료배송인지.

  const [form] = Form.useForm();
  const [defaultform] = Form.useForm();
  const [data, setData] = useState<Item[]>([]);
  const [editingKey, setEditingKey] = useState("");

  const [primeKey, setPrimeKey] = useState<number[]>([]);

  const { token } = theme.useToken();
  const tagPlusStyle = {
    background: token.colorBgBase,
    borderStyle: "none",
  };
  const tagFocusStyle = {
    background: token.colorFill,
    borderStyle: "solid",
  };

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ color: "", size: "", amount: "", ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "색상",
      dataIndex: "color",
      width: "25%",
      editable: false,
    },
    {
      title: "사이즈",
      dataIndex: "size",
      width: "15%",
      editable: false,
    },
    {
      title: "옵션가",
      dataIndex: "optionalPrice",
      width: "25%",
      editable: true,
    },
    {
      title: "재고수량",
      dataIndex: "amount",
      width: "15%",
      editable: true,
    },
    {
      title: "수정",
      dataIndex: "operation",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography.Link>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            변경
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "amount" ? "number" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleCreateOptionJons = () => {
    const product_option = [];
    for (let i = 0; i < colorOptions.length; i++) {
      for (let j = 0; j < sizeOptions.length; j++) {
        var obj = {
          key: colorOptions[i] + "," + sizeOptions[j],
          color: colorOptions[i],
          size: sizeOptions[j],
          amount: 0,
          optionalPrice: 0,
        };
        product_option.push(obj);
      }
    }
    console.log(product_option);

    setData(product_option);
    setUsedOptioned(true);
  };

  const startEdit = (imageList: string[], stateFunc: Function) => {
    const imageFileList = imageList;
    const arr = [];
    for (let i = 0; i < imageFileList.length; i++) {
      const previewFile: UploadFile = {
        uid: `done file ${i}`,
        name: imageFileList[i],
        status: "done",
        url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
      };
      arr.push(previewFile);
    }
    stateFunc(arr);
  };

  const handleOnFinish = async (value: any) => {
    try {
      const ddd = value.hashtag as HashTag[];
      const hashTag = ddd.length
        ? ddd.map(({ value, label }) => ({ id: value, tag: label }))
        : null;

      const productRepresentativeImage: string[] =
        (await handleUploadCompMultiFiles(uploadFileList, "product")) ?? [];
      const productAdditionalImages: string[] =
        (await handleUploadCompMultiFiles(additionalImgList, "product")) ?? [];
      let amountSum = 0;

      const re_build_type_data = data.map((item, index) => {
        amountSum += Number(item.amount);

        return {
          pk: primeKey[index],
          key: item.key,
          color: item.color,
          size: item.size,
          amount: Number(item.amount),
          optionalPrice: Number(item.optionalPrice),
        };
      });
      console.log("여기야 여기", re_build_type_data);
      // return;
      const result = {
        ...value,
        categoryLg: currentLgCategory?.id, //상품 카테고리 대분류
        categoryMd: currentMdCategory?.id, //상품 카테고리 중분류
        categorySm: currentSmCategory?.id, //상품 카테고리 소분류
        // hashTag: hashTag, //상품 해시태그 리스트
        productRepresentativeImage: productRepresentativeImage, //상품 대표이미지
        productAdditionalImages: productAdditionalImages, //상품 상세 이미지 리스트
        usedOptioned: usedOptioned, //상품 옵션이 적용되었는지 체크 ex) true=상품옵션 적용됨.
        colorOptions: colorOptions, // 상품 옵션 컬러 리스트
        sizeOptions: sizeOptions, // 상품 옵션 사이즈 리스트
        isDiscounted: isDiscounted, // 상품 할인 여부 ex) true=상품에 할인 적용됨.
        discountPrice: isDiscounted ? Number(value.discountPrice) : 0, // 상품 할인 가격, 할인된 상품의 가격이 아닌 상품가격에 할인할 가격.
        isFreeDelivery: isFreeDelivery, // 무료배송여부 ex) true=무료배송
        deliveryFee: !isFreeDelivery ? Number(value.deliveryFee) : 0, //배송비
        originPrice: Number(value.originPrice), //판매가
        estimatedShipDate: Number(value.estimatedShipDate), //배송 예정일.
        productStockAmount: usedOptioned // 상품 재고수량.
          ? amountSum
          : Number(value.productStockAmount),
        optionalProductInfo: usedOptioned ? re_build_type_data : null,
        returnShippingFeeOneWay: Number(value.returnShippingFeeOneWay),
        returnShippingFeeRoundTrip: Number(value.returnShippingFeeRoundTrip),
      };
      type ResultType = typeof result;
      type OmittedResultType = Omit<ResultType, "isOption">;
      const omittedResult: OmittedResultType = result;

      const response = await LPPAXIOS.put(`/seller/product/${id!}`, {
        ...omittedResult,
      });
      if (hashTag) {
        await LPPAXIOS.put(`/seller/hashtag/product/${id!}`, {
          data: hashTag,
        });
      } else {
        await LPPAXIOS.put(`/seller/hashtag/product/${id!}`, {
          data: [{ id: 0, tag: "" }],
        });
      }
      api.success({
        message: "상품 수정이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.Products.Detail.url(id!), { replace: true });
      }, 500);
    } catch (error) {
      console.log("왜 에러가 발생하느냐", error);
      api.error({
        message: "상품 수정이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onClickLgCategory = (value: Category) => {
    if (currentLgCategory && currentLgCategory.name === value.name) {
      setCurrentLgCategory(null);
    } else {
      setCurrentLgCategory(value);
    }

    setCurrentMdCategory(null);
    setCurrentSmCategory(null);
  };
  const onClickMdCategory = (value: Category) => {
    setCurrentMdCategory(value);
    setCurrentSmCategory(null);
  };
  const onClickSmCategory = (value: Category) => {
    setCurrentSmCategory(value);
  };

  useLayoutEffect(() => {
    //상품 등록 상세정보 가져오기.

    setLoading(true);
    LPPAXIOS.get<Category[]>(`/seller/category`)
      .then((resolve) => {
        setCategory(resolve.data);

        LPPAXIOS.get<Product>(`seller/product/${id!}`)
          .then((res) => {
            const productExample = res.data;
            setPrimeKey(
              res.data.optionalProductInfo.map((item: any) => item.id)
            );

            console.log("primeKey", primeKey);
            setCurrentLgCategory(
              resolve.data.find(
                (item) => item.id === productExample.categoryLg
              ) ?? null
            );
            setCurrentMdCategory(
              resolve.data.find(
                (item) => item.id === productExample.categoryMd
              ) ?? null
            );
            setCurrentSmCategory(
              resolve.data.find(
                (item) => item.id === productExample.categorySm
              ) ?? null
            );
            //카테고리 옵션
            defaultform.setFieldsValue({
              ...productExample,
              isOption: productExample.usedOptioned,
              sizeOptions: productExample.sizeOptions.filter(
                (option) => option !== ""
              ),
              colorOptions: productExample.colorOptions.filter(
                (option) => option !== ""
              ),
              hashtag: productExample.hashtag
                ? productExample.hashtag.map((item) => {
                    return { label: item.tag, value: item.id };
                  })
                : [],
            });

            // setHashTagList(
            //   productExample.hashtag
            //     ? productExample.hashtag.map((item) => {
            //         return { label: item.tag, value: item.id };
            //       })
            //     : []
            // );
            //할인 여부 옵션
            setIsDiscounted(productExample.isDiscounted);
            //상품 옵션 설정 여부
            setOptioned(productExample.usedOptioned);
            //상품 색상 리스트
            setSizeOptions(
              productExample.sizeOptions.filter((option) => option !== "")
            );
            //상품 사이즈 리스트
            setColorOptions(
              productExample.colorOptions.filter((option) => option !== "")
            );
            //상품 옵션 적용 여부
            setUsedOptioned(productExample.usedOptioned);
            //대표 이미지
            startEdit(
              productExample.productRepresentativeImage,
              setUploadFileList
            );
            //추가 이미지 리스트
            startEdit(
              productExample.productAdditionalImages ?? [],
              setAdditionalImgList
            );
            // 상세 설명
            // setProductDetailInfo(productExample.productDetailInfo);

            //저장한 옵션 상세 정보 가져오고 해당 정보가 있다면 해당 정보 표시하기.
            setData(productExample.optionalProductInfo ?? []);
          })
          .catch(function (error) {
            api.error({
              message: "상품 상세 정보를 가져오지 못하였습니다.",
              description: "다시 시도해주시기 바랍니다.",
            });
          });
      })
      .catch(function (error) {
        api.error({
          message: "카테고리 목록을 불러오지 못했습니다.",
          description: "다시 시도해주시기 바랍니다.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  // 이미지 업로드 드래그앤 드롭으로 순서 변경
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setAdditionalImgList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };
  const _onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setUploadFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setAdditionalImgList(newupdatedFileList);
  };

  const _onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setUploadFileList(newupdatedFileList);
  };

  const onChangeHashTag = (newValue: HashTag[]) => {
    setHashTagList(newValue);
  };
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <div className="p-2">
        <Form
          labelWrap
          className="flex flex-col gap-5"
          colon={false}
          form={defaultform}
          onFinish={handleOnFinish}
          scrollToFirstError
        >
          <CardWidget title={<Typography.Text>카테고리</Typography.Text>}>
            <Form.Item
              name="categories"
              extra="저장된 상품의 대분류 카테고리는 수정할 수 없습니다."
              rules={[
                {
                  validator: (rule: RuleObject, value: any) => {
                    // console.log(
                    //   "line::511 ->",
                    //   "currentLgCategory::",
                    //   currentLgCategory,
                    //   "currentMdCategory::",
                    //   currentMdCategory
                    // );
                    if (currentLgCategory && currentMdCategory) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                  message: "카테고리 설정은 필수입니다.",
                },
              ]}
            >
              <div className="flex w-full gap-2">
                {/* 대분류 */}
                <div className="flex flex-col w-1/3 h-[200px] overflow-y-auto bg-slate-100 gap-2 p-2">
                  {category.map((item, index) => {
                    if (item.parentId === 0) {
                      return (
                        <div
                          className="w-full hover:bg-slate-300"
                          onClick={() => onClickLgCategory(item)}
                          style={
                            currentLgCategory?.id === item.id
                              ? tagFocusStyle
                              : tagPlusStyle
                          }
                          key={`lg-ctg-${index}-${item.name}`}
                        >
                          {item.name}
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="flex flex-col w-1/3 h-[200px] overflow-y-auto bg-slate-100 gap-2 p-2">
                  {category.map((item, index) => {
                    if (item.parentId === currentLgCategory?.id) {
                      return (
                        <div
                          className="w-full hover:bg-slate-300"
                          style={
                            currentMdCategory?.id === item.id
                              ? tagFocusStyle
                              : tagPlusStyle
                          }
                          onClick={() => onClickMdCategory(item)}
                          key={`md-ctg-${index}-${item.name}`}
                        >
                          {item.name}
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="flex flex-col w-1/3 h-[200px] overflow-y-auto bg-slate-100 gap-2 p-2">
                  {category.map((item, index) => {
                    if (item.parentId === currentMdCategory?.id) {
                      return (
                        <div
                          className="w-full hover:bg-slate-300"
                          style={
                            currentSmCategory?.id === item.id
                              ? tagFocusStyle
                              : tagPlusStyle
                          }
                          key={`sm-ctg-${index}-${item.name}`}
                          onClick={() => onClickSmCategory(item)}
                        >
                          {item.name}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </Form.Item>
          </CardWidget>
          <CardWidget title={<Typography.Text>상품명</Typography.Text>}>
            <Form.Item
              rules={[{ required: true, message: "상품명 설정은 필수입니다." }]}
              name="productName"
              extra="판매 상품과 직접 관련이 없는 다른 상품명, 유명 상품 유사문구,
              스팸성 키워드 입력 시 관리자에 의해 판매 금지 될 수 있습니다."
            >
              <Input className="rounded-none" showCount maxLength={100} />
            </Form.Item>
          </CardWidget>

          <CardWidget title={<Typography.Text>상품설명</Typography.Text>}>
            <FormItem
              isLast
              label="상세설명"
              name="productDetailInfo"
              isrequired
            >
              <Input className="rounded-none" />
            </FormItem>
          </CardWidget>
          <CardWidget title={<Typography.Text>상품 상세</Typography.Text>}>
            <FormItem label="해시태그" name="hashtag">
              <DebounceSelect
                mode="multiple"
                placeholder="해시태그를 검색하세요."
                fetchOptions={fetchUserList}
                onChange={(value) => onChangeHashTag(value as HashTag[])}
                style={{ width: "100%" }}
                // defaultValue={hashTagList} // <- 현재 값으로 설정
              />
            </FormItem>
            <FormItem label="전용상품" name="genderLabel" isLast>
              <Radio.Group buttonStyle="solid">
                <Radio.Button className="rounded-none" value={1}>
                  남여공용
                </Radio.Button>
                <Radio.Button className="rounded-none" value={2}>
                  남성전용
                </Radio.Button>
                <Radio.Button className="rounded-none" value={3}>
                  여성전용
                </Radio.Button>
              </Radio.Group>
            </FormItem>
          </CardWidget>

          <CardWidget title={<Typography.Text>판매가</Typography.Text>}>
            <FormItem isrequired label="판매가" name="originPrice">
              <NumberInput
                style={{ width: 210 }}
                placeholder="숫자만 입력"
                name="originPrice"
              />
            </FormItem>

            <FormItem label="할인" name="isDiscounted" isLast={isDiscounted}>
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => setIsDiscounted(e.target.value)}
              >
                <Radio.Button className="rounded-none" value={true}>
                  설정함
                </Radio.Button>
                <Radio.Button className="rounded-none" value={false}>
                  설정안함
                </Radio.Button>
              </Radio.Group>
            </FormItem>
            {isDiscounted && (
              <Form.Item
                required={false}
                label={
                  <div className="w-[120px] flex justify-start">
                    <span className="text-left">전체 할인</span>
                  </div>
                }
                name="discountPrice"
                rules={[
                  {
                    required: isDiscounted,
                    message: "전체 할인 설정은 필수입니다.",
                  },
                  {
                    validator(rule, value, callback) {
                      // console.log(defaultform.getFieldValue("originPrice"));
                      // console.log(value);
                      if (
                        Number(value) >=
                        Number(defaultform.getFieldValue("originPrice"))
                      ) {
                        console.log("크다");
                        return Promise.reject();
                      } else {
                        return Promise.resolve();
                      }
                    },
                    message: "전체 할인 가격이 판매가보다 크거나 같습니다.",
                  },
                ]}
              >
                <NumberInput
                  style={{ width: 210 }}
                  placeholder="숫자만 입력"
                  name="discountPrice"
                />
              </Form.Item>
            )}
          </CardWidget>

          <CardWidget title={<Typography.Text>옵션</Typography.Text>}>
            <Form.Item
              name="colorOptions"
              label={
                <div className="w-[120px] flex justify-start">
                  <span className="text-left">색상</span>
                </div>
              }
              rules={[
                {
                  validator: (rule: RuleObject, value: any) => {
                    if (colorOptions.length > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                  message: "색상 옵션은 필수입니다.",
                },
              ]}
            >
              <Select
                disabled
                mode="tags"
                style={{ width: "100%" }}
                placeholder="색상을 입력하세요."
                // options={colorOptions}
                value={colorOptions}
                onChange={(e) => {
                  console.log(e);

                  //빈공백이나 이상한 문자열은 지우도록 해야할 듯함.
                  //' '값도 들어감.
                  let truntfilter = e.filter((text: string) => {
                    console.log(text.trim().length);
                    if (text.trim().length !== 0) return text;
                  });
                  console.log(truntfilter);
                  setColorOptions(truntfilter);
                }}
              />
            </Form.Item>

            {/* <FormItem label="색상" name="colorOptions">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="색상을 입력하세요."
                onChange={(e) => {
                  setColorOptions(e);
                }}
              />
            </FormItem> */}

            <FormItem label="기본 사이즈 옵션" name="sizeOptions">
              <Checkbox.Group
                disabled
                style={{ width: "100%" }}
                value={sizeOptions}
                onChange={(e) => {
                  console.log(e);
                  setSizeOptions(e);
                }}
              >
                <Row gutter={[24, 24]}>
                  {sizeOptionCheckboxList.map((item, index) => {
                    return (
                      <Col key={`size-${index}-opt`} span={3}>
                        <Checkbox value={item}>{item}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </FormItem>
            <Form.Item
              name="sizeOptions"
              label={
                <div className="w-[120px] flex justify-start">
                  <span className="text-left">사이즈 추가옵션</span>
                </div>
              }
              rules={[
                {
                  validator: (rule: RuleObject, value: any) => {
                    if (sizeOptions.length > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                  message: "사이즈 옵션은 필수입니다.",
                },
              ]}
            >
              <Select
                disabled
                mode="tags"
                value={sizeOptions}
                style={{ width: "100%" }}
                placeholder="사이즈를 입력하세요. 사이즈의 입력은 엔터로 구분됩니다."
                onChange={(e) => {
                  console.log(e);
                  let truntfilter = e.filter((text: string | number) => {
                    // 'text'가 문자열인 경우에만 'trim()'을 호출하고, 그렇지 않으면 'text'를 그대로 사용합니다.
                    const processedText =
                      typeof text === "string" ? text.trim() : text;
                    // 'processedText'가 숫자이거나 비어있지 않은 문자열이면, 'true'를 반환하여 필터를 통과시킵니다.
                    return (
                      typeof processedText === "number" ||
                      processedText.length !== 0
                    );
                  });
                  console.log(truntfilter);
                  setSizeOptions(truntfilter);
                }}
              />
            </Form.Item>

            <Form.Item
              name="usedOptioned"
              rules={[
                {
                  validator: (rule: RuleObject, value: any) => {
                    if (usedOptioned) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                  message: " 옵션을 적용해주세요.",
                },
              ]}
            >
              <Button
                disabled
                block
                className="rounded-none"
                onClick={handleCreateOptionJons}
                // disabled={colorOptions.length === 0 || sizeOptions.length === 0}
              >
                옵션목록으로 적용
                <ArrowDownOutlined rev={undefined} />
              </Button>
            </Form.Item>
            {usedOptioned && (
              <>
                <Divider />
                <Form form={form} component={false}>
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ y: 400 }}
                  />
                </Form>
              </>
            )}
          </CardWidget>

          <CardWidget title={<Typography.Text>상품이미지</Typography.Text>}>
            <FormItem
              isrequired
              label="카테고리 및 제품창에서 가장 먼저보이는 대표사진 "
              name="productRepresentativeImage"
              extra="최대 10장까지 등록가능합니다."
            >
              <DndContext sensors={[sensor]} onDragEnd={_onDragEnd}>
                <SortableContext
                  items={uploadFileList.map((i) => i.uid)}
                  strategy={verticalListSortingStrategy}
                >
                  <Upload
                    multiple
                    maxCount={10}
                    fileList={uploadFileList}
                    accept=".jpg, .jpeg, .png, .webp"
                    listType="picture"
                    className="upload-list-inline"
                    showUploadList={{ showPreviewIcon: true }}
                    customRequest={({ file, onSuccess }) => {
                      const res = "Ok";
                      onSuccess!(res);
                    }}
                    beforeUpload={(file) => {
                      const isImage = file.type.startsWith("image/");
                      if (!isImage) {
                        message.error(`${file.name} is not an image file`);
                      }
                      return isImage || Upload.LIST_IGNORE;
                    }}
                    onChange={_onChange}
                    itemRender={(originNode, file) => (
                      <DraggableUploadListItem
                        originNode={originNode}
                        file={file}
                      />
                    )}
                  >
                    <Button icon={<UploadOutlined rev={undefined} />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </SortableContext>
              </DndContext>
            </FormItem>

            <FormItem
              isLast
              label={`상세이미지`}
              name="productAdditionalImages"
              extra={
                <p>
                  추가 이미지는 최대 30개까지 설정할 수 있습니다.
                  <br />
                  jpg, jpeg, gif, png, bmp 형식의 정지 이미지만 등록됩니다.
                  <br />
                  제품 상세페이지 제품사진 및 상품주요정보 , 상품정보제공고시등
                  노출
                </p>
              }
            >
              <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                <SortableContext
                  items={additionalImgList.map((i) => i.uid)}
                  strategy={verticalListSortingStrategy}
                >
                  <Upload
                    multiple
                    maxCount={30}
                    fileList={additionalImgList}
                    accept=".jpg, .jpeg, .png, .webp"
                    listType="picture"
                    className="upload-list-inline"
                    showUploadList={{ showPreviewIcon: false }}
                    customRequest={({ file, onSuccess }) => {
                      const res = "Ok";
                      onSuccess!(res);
                    }}
                    beforeUpload={(file) => {
                      const isImage = file.type.startsWith("image/");
                      if (!isImage) {
                        message.error(`${file.name} is not an image file`);
                      }
                      return isImage || Upload.LIST_IGNORE;
                    }}
                    onChange={onChange}
                    itemRender={(originNode, file) => (
                      <DraggableUploadListItem
                        originNode={originNode}
                        file={file}
                      />
                    )}
                  >
                    <Button icon={<UploadOutlined rev={undefined} />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </SortableContext>
              </DndContext>
            </FormItem>
          </CardWidget>

          <CardWidget title={<Typography.Text>상품 주요정보</Typography.Text>}>
            <FormItem isrequired label="모델명" name="modelName">
              <Input className="rounded-none" />
            </FormItem>

            <FormItem isrequired label="브랜드" name="brandName">
              <Input className="rounded-none" />
            </FormItem>

            <FormItem isrequired label="제조사" name="manufacturerCompany">
              <Input className="rounded-none" />
            </FormItem>
          </CardWidget>

          <CardWidget
            title={<Typography.Text>상품정보제공고시</Typography.Text>}
          >
            <FormItem isrequired label="상품군" name="productGroup">
              <Input className="rounded-none" />
            </FormItem>

            <FormItem isrequired label="제품소재" name="productMaterial">
              <Input className="rounded-none" />
            </FormItem>

            <FormItem isrequired label="색상" name="productColor">
              <Input className="rounded-none" />
            </FormItem>

            <FormItem isrequired label="치수" name="productSize">
              <Input className="rounded-none" />
            </FormItem>

            <FormItem isrequired label="제조자" name="manufacturer">
              <Input className="rounded-none" />
            </FormItem>

            <FormItem isrequired label="제조국" name="countryOfManufacture">
              <Input className="rounded-none" />
            </FormItem>

            <FormItem
              isrequired
              label="세탁방법 및 취급 시 주의사항"
              name="washingMethodAndHandlingPrecautions"
            >
              <Input className="rounded-none" />
            </FormItem>

            <FormItem
              isrequired
              label="제조연월"
              name="manufacturingYearAndMonth"
            >
              <Input className="rounded-none" />
            </FormItem>

            <FormItem
              isrequired
              label="품질보증기준"
              name="qualityAssuranceStandards"
            >
              <Input className="rounded-none" />
            </FormItem>

            <FormItem
              isLast
              isrequired
              label="A/S 책임자와 전화번호"
              name="managerAndPhoneNumber"
            >
              <Input className="rounded-none" />
            </FormItem>
          </CardWidget>

          <CardWidget title={<Typography.Text>배송</Typography.Text>}>
            {/* <FormItem
              label="배송비"
              name="isFreeDelivery"
              isLast={!isFreeDelivery}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => setIsFreeDelivery(e.target.value)}
              >
                <Radio.Button className="rounded-none" value={false}>
                  유료배송
                </Radio.Button>
                <Radio.Button className="rounded-none" value={true}>
                  무료배송
                </Radio.Button>
              </Radio.Group>
            </FormItem>
            {!isFreeDelivery && (
              <Form.Item
                name="deliveryFee"
                label={
                  <div className="w-[120px] flex justify-start">
                    <span className="text-left">배송비</span>
                  </div>
                }
                rules={[
                  {
                    validator: (rule: RuleObject, value: any) => {
                      if (!isFreeDelivery && value >= 3000) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    },
                    message: "배송비는 3000원 이상입니다.",
                  },
                ]}
              >
                <NumberInput
                  disabled={!!isFreeDelivery}
                  style={{ width: 210 }}
                  placeholder="숫자만 입력"
                  name="deliveryFee"
                />
              </Form.Item>
            )} */}

            <FormItem
              isrequired
              label="발송 예정일"
              name="estimatedShipDate"
              isLast
            >
              <Select
                style={{ width: 100 }}
                allowClear
                options={[
                  { value: 2, label: "2일 이내" },
                  { value: 3, label: "3일 이내" },
                  { value: 4, label: "4일 이내" },
                  { value: 5, label: "5일 이내" },
                  { value: 6, label: "6일 이내" },
                  { value: 7, label: "7일 이내" },
                ]}
              />
            </FormItem>
          </CardWidget>

          <CardWidget title={<Typography.Text>반품/교환</Typography.Text>}>
            <FormItem
              isrequired={true}
              label="반품/교환 택배사 "
              name="returnExchangeCourier"
            >
              <Select
                style={{ width: "100%" }}
                allowClear
                options={[
                  { value: "CJ대한통운", label: "CJ대한통운" },
                  { value: "한진택배", label: "한진택배" },
                  { value: "롯데택배", label: "롯데택배" },
                  { value: "우체국택배", label: "우체국택배" },
                  { value: "로젠택배", label: "로젠택배" },
                ]}
              />
            </FormItem>
            <FormItem
              isrequired={true}
              label="반품배송비(편도)"
              name="returnShippingFeeOneWay"
              extra="최초 배송시의 배송비가 무료인 경우 왕복 배송비 0원이 청구됩니다."
            >
              <NumberInput
                style={{ width: 200 }}
                name="returnShippingFeeOneWay"
                placeholder="숫자만 입력(원)"
              />
            </FormItem>
            <FormItem
              isrequired
              label="반품배송비(왕복)"
              name="returnShippingFeeRoundTrip"
            >
              <NumberInput
                style={{ width: 200 }}
                name="returnShippingFeeRoundTrip"
                placeholder="숫자만 입력(원)"
              />
            </FormItem>
            <FormItem
              isLast
              isrequired
              label="반품/교환지"
              name="returnExchangeLocation"
            >
              <Input className="rounded-none" />
            </FormItem>
          </CardWidget>

          <CardWidget title={<Typography.Text>A/S, 특이사항</Typography.Text>}>
            <FormItem label="A/S전화번호" name="asPhoneNumber" isrequired>
              <Input.TextArea className="rounded-none" />
            </FormItem>
            <FormItem
              extra="법률에 근거하지 않은 판매자의 임의적인 A/S규정 및 청약철회 기준 안내 시, 이용정지 및 관련 법에 의거하여 제제될 수 있습니다."
              label="A/S안내"
              name="asInformation"
              isrequired
            >
              <Input.TextArea className="rounded-none" />
            </FormItem>
            <FormItem
              extra={
                <p>
                  청약철회, 배송기간 및 방법, 교환/반품의 보증 및 추가비용,
                  판매일시/판매지역/판매수량/인도지역 등과 관련해서 특이사항이
                  있는 경우,해외배송상품인 경우 입력
                  <br />
                  법률에 근거하지 않은 판매자의 임의적인 A/S규정 및 청약철회
                  기준 안내 시, 이용정지 및 관련 법에 의거하여 제제될 수
                  있습니다.
                </p>
              }
              label="판매자특이사항"
              name="sellerSpecifics"
              isrequired
              isLast
            >
              <Input.TextArea className="rounded-none" />
            </FormItem>
          </CardWidget>

          <Divider />
          <Button type="primary" htmlType="submit" className="rounded-none">
            상품 수정
          </Button>
          <Popconfirm
            title="상품수정 취소"
            description="상품수정 취소를 하시면 현재 입력하신 정보는 저장되지 않습니다."
            onConfirm={() => navigate(-1)}
          >
            <Button className="rounded-none">수정 취소</Button>
          </Popconfirm>
        </Form>
      </div>
    </>
  );
};
