import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../LPPUrls";
import { AdminLoginPage } from "../../pages/AdminLoginPage";
import AgreementPage from "../../pages/AgreementPage";
import { FindPasswordPage } from "../../pages/FindPasswordPage";
import { currentSeller, getItem } from "../../utils/localStorage";
import { LPPAuthCommonLayout } from "../Layout/LPPAuthCommonLayout";

export const LPPAuthRoutes = () => {
  const localData = getItem(currentSeller);
  if (localData !== null) {
    return <Navigate to={LPPUrls.Admin.Root.pathName} replace />;
  }
  return (
    <LPPAuthCommonLayout>
      <Routes>
        <Route
          path={LPPUrls.Auth.Login.Main.pathName}
          element={<AdminLoginPage />}
        />
        {/* seller 가입 신청 페이지 */}
        <Route
          path={LPPUrls.Auth.FindPassword.pathName}
          element={<FindPasswordPage />}
        />
        {/* 가입 동의 이용 약관 페이지 */}
        <Route
          path={LPPUrls.Auth.Agreement.pathName}
          element={<AgreementPage />}
        />
        <Route
          path={"*"}
          element={<Navigate to={LPPUrls.Auth.Login.Main.pathName} />}
        />
      </Routes>
    </LPPAuthCommonLayout>
  );
};
