import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Result,
  Button,
  Card,
  Row,
  Col,
  Avatar,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useState } from "react";
import { AxiosError } from "axios";
import { HContent } from "../../../components/common/HContent";
import { MatchTable } from "./type";

const { Meta } = Card;
const { Header } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const match = state?.match as MatchTable;
  const navigate = useNavigate();
  const [error, setError] = useState<any | AxiosError | null>(null);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onClickBack = () => {
    navigate(LPPUrls.Admin.Match.Main.url());
  };

  if ((error !== null && error.response.status === 404) || !match)
    return (
      <Result
        title={error?.message}
        subTitle="해당 매칭 내역을 찾을 수 없습니다."
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );

  return (
    <>
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>매칭 상세 보기</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Match.Main.url()}>매칭 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>매칭 상세 보기</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Typography.Title level={4}>매칭 상세 조회</Typography.Title>

        <Row gutter={[12, 16]}>
          <Col sm={24} md={12}>
            <Card title="MD">
              <Meta
                avatar={
                  <Avatar
                    size={64}
                    src={`${process.env.REACT_APP_IMG_ENDPOINT}/${match.mdProfile}`}
                  />
                }
                title={match.mdNickName}
              />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card title="Creator">
              <Meta
                avatar={
                  <Avatar
                    size={64}
                    src={`${process.env.REACT_APP_IMG_ENDPOINT}/${match.profile}`}
                  />
                }
                title={match.nickName}
                description={
                  <p>
                    연락처: {match.phone}
                    <br />
                    주소: {match.address} {match.addressDetail}
                  </p>
                }
              />
            </Card>
          </Col>
        </Row>
      </HContent>
    </>
  );
};
