export const sizeOptionCheckboxList = [
  220,
  225,
  230,
  235,
  240,
  245,
  250,
  255,
  260,
  265,
  270,
  275,
  280,
  285,
  290,
  295,
  300,
  305,
  310,
  "XS",
  "S",
  "M",
  "L",
  "XL",
];
