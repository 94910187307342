import { arrayMove, useSortable } from "@dnd-kit/sortable";
import { DraggableUploadListItemProps } from "./types";
import { CSS } from "@dnd-kit/utilities";
import { DragEndEvent, PointerSensor, useSensor } from "@dnd-kit/core";
import { UploadFile, UploadProps } from "antd";

export const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  );
};

interface IuseSortUploadProps {
  setUploadFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  setAdditionalImgList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
}
export const useSortUpload = (props: IuseSortUploadProps) => {
  const { setUploadFileList, setAdditionalImgList } = props;
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setAdditionalImgList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };
  const _onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setUploadFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setAdditionalImgList(newupdatedFileList);
  };

  const _onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setUploadFileList(newupdatedFileList);
  };

  return { sensor, onDragEnd, _onDragEnd, onChange, _onChange };
};
