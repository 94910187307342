import { UploadFile } from "antd";
import axios from "axios";
import { LPPAXIOS } from "../framework/api/core.api";

export default async function uploadImgToS3(
  file: ArrayBuffer,
  type: string,
  url: string,
  path: string
) {
  const res = await axios.put(url, file, {
    headers: {
      "Content-Type": type,
    },
  });
  if (res.status === 200) {
    return path;
  }
  return "";
}

interface GETUPLOADURL {
  path: string;
  url: string;
}
export const handleUploadCompMultiFiles = async (
  uploadFileList: UploadFile[],
  imageUploadTarget: string
) => {
  try {
    if (uploadFileList.length === 0) return;
    const paths: string[] = [];
    for (let i = 0; i < uploadFileList.length; i++) {
      if (uploadFileList[i].uid.includes("done file")) {
        paths.push(
          uploadFileList[i].url!.replace(
            `${process.env.REACT_APP_IMG_ENDPOINT}/`,
            ""
          )
        );
      } else {
        const fileBuffer = await uploadFileList[i].originFileObj?.arrayBuffer();
        let fileType = uploadFileList[i].type?.split("/")[0];
        console.log(fileType);
        if (fileType === "application") {
          fileType = "file";
        }
        const res = await LPPAXIOS.get<GETUPLOADURL[]>(
          `seller/file/upload?type=${fileType}&mimeType=${uploadFileList[i].type}&imageUploadTarget=${imageUploadTarget}&num=1`
        );
        if (res.status === 200) {
          const path = await uploadImgToS3(
            fileBuffer!,
            uploadFileList[i].type!,
            res.data[0].url,
            res.data[0].path
          );
          paths.push(path);
        }
      }
    }

    return paths;
  } catch (error) {
  } finally {
  }
};
