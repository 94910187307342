import { Button, Form, theme, Upload, UploadFile } from "antd";
import { Header } from "antd/es/layout/layout";
import useNotification from "antd/es/notification/useNotification";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingSpin } from "../../../components/common/Loading";
import { PlusOutlined } from "@ant-design/icons";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";
import { HContent } from "../../../components/common/HContent";
import AntdImgCrop from "antd-img-crop";

interface GETUPLOADURL {
  path: string;
  url: string;
}

export const UPloadBannerPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);

  // 배너 사이즈: 360 x 160
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  async function uploadImgToS3(
    file: ArrayBuffer,
    type: string,
    url: string,
    path: string
  ) {
    const res = await axios.put(url, file, {
      headers: {
        "Content-Type": type,
      },
    });
    if (res.status === 200) {
      return path;
    }
    return "";
  }

  const handlePostBanner = async () => {
    try {
      setLoading(true);
      if (uploadFileList.length === 0) {
        api.error({
          message: "상품배너 이미지 등록은 필수입니다.",
          description: "이미지를 등록해주세요",
        });
        return;
      }
      const images = (await handleUploadCompMultiFiles()) ?? [];
      console.log(images);
      await LPPAXIOS.post(`/admin/product/${id}`, { image: images });
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const handleUploadCompMultiFiles = async () => {
    if (uploadFileList.length === 0) return;
    try {
      const paths: string[] = [];
      for (let i = 0; i < uploadFileList.length; i++) {
        console.log(uploadFileList[i].name);
        const fileBuffer = await uploadFileList[i].originFileObj?.arrayBuffer();
        const res = await LPPAXIOS.get<GETUPLOADURL[]>(
          `admin/files/upload?type=images&mimeType=${uploadFileList[i].type}&imageUploadTarget=banner&num=1`
        );
        if (res.status === 200) {
          const path = await uploadImgToS3(
            fileBuffer!,
            uploadFileList[i].type!,
            res.data[0].url,
            res.data[0].path
          );
          paths.push(path);
        }
      }

      return paths;
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title>배너 등록</Title>
      </Header>
      <HContent>
        <Form onFinish={handlePostBanner}>
          <Form.Item>
            <AntdImgCrop aspect={36 / 16}>
              <Upload
                fileList={uploadFileList}
                accept="image/*"
                listType="picture-card"
                showUploadList={{ showPreviewIcon: false }}
                customRequest={({ onSuccess }) => {
                  const res = "Ok";
                  setTimeout(() => {
                    onSuccess!(res);
                  }, 600);
                }}
                onChange={({ fileList }) => {
                  console.log(fileList);
                  setUploadFileList(fileList);
                }}
              >
                {uploadFileList.length >= 1 ? null : (
                  <div>
                    <PlusOutlined rev={undefined} />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </AntdImgCrop>
          </Form.Item>
          모바일환경에서 보이는 이미지의 비율을 위해 가로 360px 세로 160px의
          이미지 등록을 권장드립니다.
          <Form.Item>
            <Button htmlType="submit">배너 등록</Button>
          </Form.Item>
        </Form>
      </HContent>
    </>
  );
};
