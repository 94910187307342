import { Button, Card, Form, Input, message, Spin, Typography } from "antd";
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { LPPUrls } from "../LPPUrls";

import {
  LPPAXIOS,
  RequestPostAuth,
  ResponsePostAuth,
} from "../framework/api/core.api";

import { useState } from "react";
import { currentSeller } from "../utils/localStorage";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 64 }} spin rev={undefined} />
);

export const AdminLoginPage = () => {
  const [loading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const handleLoginAdmin = (loginValue: RequestPostAuth) => {
    setLoading(true);
    LPPAXIOS.post<ResponsePostAuth>("/seller/auth", loginValue)
      .then((resolve) => {
        localStorage.setItem(currentSeller, JSON.stringify(resolve.data));
        return navigate(LPPUrls.Admin.Root.pathName, { replace: true });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "아이디와 비밀번호가 일치하지 않습니다.",
          duration: 2,
          style: {
            marginTop: "20vh",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
    // try {
    //   setLoading(true);
    //   const res = await LPPAXIOS.post<ResponsePostAuth>(
    //     "/seller/auth",
    //     loginValue
    //   );
    //   if (res.status === 200) {
    //     localStorage.setItem(currentSeller, JSON.stringify(res.data));
    //     return navigate(LPPUrls.Admin.Root.pathName, { replace: true });
    //   }
    //   return messageApi.open({
    //     type: "error",
    //     content: "Fail to Login",
    //     duration: 2,
    //     style: {
    //       marginTop: "20vh",
    //     },
    //   });
    // } catch (error) {
    //   messageApi.open({
    //     type: "error",
    //     content: "Fail to Login",
    //     duration: 2,
    //     style: {
    //       marginTop: "20vh",
    //     },
    //   });
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    // Ver.02
    <div className="relative flex w-screen h-screen overflow-hidden bg-slate-600">
      <div
        className={
          loading
            ? "w-screen h-screen bg-white/50 absolute z-10"
            : "hidden w-screen h-screen bg-white/50 absolute z-10"
        }
      >
        <Spin
          className="relative left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          spinning={loading}
          indicator={antIcon}
          size="large"
        />
      </div>

      <div className="flex items-center justify-center w-screen h-screen">
        <div className="overflow-hidden rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg w-fit h-fit drop-shadow-md">
          <Card
            className=""
            title={
              <div className="flex justify-center w-full">
                <Typography.Title level={3} className="">
                  AZ Seller Login
                </Typography.Title>
              </div>
            }
          >
            <Form
              layout="vertical"
              name="normal_login"
              className="w-[340px] font-bold"
              initialValues={{ remember: true }}
              onFinish={handleLoginAdmin}
            >
              <Form.Item
                label={<span className="font-semibold ">ID</span>}
                name="name"
                rules={[{ required: true, message: "아이디를 입력하세요." }]}
              >
                <Input
                  prefix={<UserOutlined rev={undefined} />}
                  placeholder="ID"
                />
              </Form.Item>
              <Form.Item
                label={<span className="font-semibold ">Password</span>}
                name="password"
                rules={[{ required: true, message: "비밀번호를 입력하세요." }]}
              >
                <Input
                  prefix={<LockOutlined rev={undefined} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Link
                  className="float-right"
                  to={LPPUrls.Auth.FindPassword.url}
                >
                  셀러 가입 신청
                </Link>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="font-bold"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
      {contextHolder}
    </div>
  );
};
