import Card from "antd/es/card/Card";
import { ReactNode } from "react";

const CardWidget = ({
  title,
  children,
}: {
  title?: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <Card className="rounded-none" title={title}>
      {children}
    </Card>
  );
};

export default CardWidget;
