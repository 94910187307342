import { Button, Layout, Menu, Space, theme, Typography } from "antd";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";

import { LPPUrls } from "../../LPPUrls";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import { currentSeller, getItem as localItem } from "../../utils/localStorage";
import { LPPAXIOS } from "../../framework/api/core.api";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  theme?: "light" | "dark"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    theme,
  } as MenuItem;
}

const { Sider } = Layout;

interface ILPPAdminCommonLayoutProps {
  children: React.ReactNode;
  role?: "seller" | "admin";
}

export const LPPAdminCommonLayout = (props: ILPPAdminCommonLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { nickName } = localItem(currentSeller);
  const { children } = props;
  const [current, setCurrent] = useState("");
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items: MenuProps["items"] = [
    // getItem(
    //   <Link to={LPPUrls.Admin.Notice.Main.url()}>공지사항</Link>,
    //   LPPUrls.Admin.Notice.Main.key
    // ),
    getItem(
      <Link to={LPPUrls.Admin.Store.Main.url()}>스토어 전시관리</Link>,
      LPPUrls.Admin.Store.Main.key
    ),
    getItem("상품관리", "ProductsMain", null, [
      getItem(
        <Link to={LPPUrls.Admin.Products.Main.url()}>상품 조회/수정</Link>,
        LPPUrls.Admin.Products.Main.key
      ),
      getItem(
        <Link to={LPPUrls.Admin.Products.Create.url}>상품 등록</Link>,
        LPPUrls.Admin.Products.Create.key
      ),
    ]),

    getItem("판매관리", "SalesManagementGroup", null, [
      getItem(
        <Link to={LPPUrls.Admin.SalesManagement.Main.url()}>
          주문 통합 관리
        </Link>,
        LPPUrls.Admin.SalesManagement.Main.key
      ),
      // getItem(
      //   <Link to={LPPUrls.Admin.Delivery.Main.url()}>발주 확인/발송 관리</Link>,
      //   LPPUrls.Admin.Delivery.Main.key
      // ),
    ]),

    getItem(
      <Link to={LPPUrls.Admin.Review.Main.url()}>리뷰조회</Link>,
      LPPUrls.Admin.Review.Main.key
    ),
    getItem(
      <Link to={LPPUrls.Admin.Calculate.Main.url()}>정산관리</Link>,
      LPPUrls.Admin.Calculate.Main.key
    ),
    getItem(
      <Link to={LPPUrls.Admin.Match.Main.url()}>매칭관리</Link>,
      LPPUrls.Admin.Match.Main.key
    ),

    // getItem(
    //   <Link to={LPPUrls.Admin.Term.Main.url()}>쇼핑정책</Link>,
    //   LPPUrls.Admin.Term.Main.key
    // ),
  ];

  const handleLogout = async () => {
    try {
      const res = await LPPAXIOS.delete("/seller/auth");
      if (res.status === 204) {
        localStorage.removeItem(currentSeller);
        return navigate(LPPUrls.Auth.Root.pathName, { replace: true });
      }
    } catch (error) {}
  };

  useEffect(() => {
    setCurrent(location.pathname.split("/").slice(2, 4).join(""));
  }, [location.pathname]);

  return (
    <Layout>
      <Layout>
        <Sider
          className="scrollbar-hide"
          style={{
            // background: "#4D9226",
            overflowY: "auto",
          }}
        >
          {process.env.REACT_APP_CHECK_ENV === "_dev_" && (
            <Typography.Title level={1} style={{ color: "white" }}>
              ..Develop..
            </Typography.Title>
          )}

          <div className="w-full h-[60px] p-2">
            <div className="w-full h-full text-xl text-center text-white rounded-lg">
              AZ Seller
            </div>
          </div>

          <Menu
            items={items}
            mode="inline"
            selectedKeys={[current]}
            // theme={collapsed ? "dark" : "light"}
          />
        </Sider>
        <Layout className="h-screen overflow-auto bg-gray-50">
          <Header
            style={{
              background: colorBgContainer,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Space>
              <Typography.Text>{nickName}</Typography.Text>
              <Button onClick={handleLogout}>Logout</Button>
            </Space>
          </Header>
          {children}
        </Layout>
      </Layout>
    </Layout>
  );
};
