const topLevelAdmin = (name: string) => {
  return {
    Main: {
      url: (page: number = 1, keyword: string = "") =>
        `/seller/${name}?page=${page}${keyword}`,
      pathName: `/${name}/*`,
      key: name,
    },

    ReportList: {
      url: `/seller/${name}/ReportList/`,
      pathName: `ReportList/*`,
      key: `${name}ReportList`,
    },

    Detail: {
      url: (id: string | number) => `/seller/${name}/detail/${id}`,
      pathName: "detail/:id",
      key: `${name}detail`,
    },
    Update: {
      url: (id: string | number) => `/seller/${name}/update/${id}`,
      pathName: "update/:id",
      key: `${name}update`,
    },
    Create: {
      url: `/seller/${name}/create/`,
      pathName: `create/`,
      key: `${name}create`,
    },
    BoardInfo: {
      url: (id: string | number) => `/seller/${name}/boardInfo/${id}`,
      pathName: "boardInfo/:id",
      key: `${name}boardInfo`,
    },
    UploadBanner: {
      url: (id: string | number) => `/seller/${name}/uploadBanner/${id}`,
      pathName: "uploadBanner/:id",
      key: `${name}uploadBanner`,
    },
  };
};

export const LPPUrls = {
  Auth: {
    Root: {
      url: "/auth",
      pathName: "/auth/*",
    },
    Login: {
      Main: {
        url: "/auth/adminLogin",
        pathName: "adminLogin",
      },
    },
    FindPassword: {
      url: "/auth/regist",
      pathName: "regist",
    },
    Agreement: {
      url: "/auth/agreement",
      pathName: "agreement",
    },
  },
  Admin: {
    // url:'/admin',
    // Landing: '/admin/adminAccount/',
    Root: {
      url: "/seller",
      pathName: "/seller/*",
    },
    MyPage: topLevelAdmin("MyPage"),
    /** 공지사항 */
    Notice: topLevelAdmin("Notice"),
    Term: topLevelAdmin("Term"),
    /**상품관리 */
    Products: {
      ...topLevelAdmin("Products"),
      Prohibit: {
        url: (page: number = 1, keyword: string = "") =>
          `/seller/Products/prohibit?page=${page}${keyword}`,
        pathName: "prohibit/",
        key: `Productsprohibit`,
      },
      ProhibitDetail: {
        url: (id: string | number) => `/seller/Products/prohibit/Detail/${id}`,
        pathName: "prohibit/Detail/:id",
        key: `Prohibitdetail`,
      },
    },
    /**판매관리 */
    SalesManagement: topLevelAdmin("SalesManagement"),
    Orders: topLevelAdmin("Orders"),
    Review: topLevelAdmin("Review"),
    Calculate: topLevelAdmin("Calculate"),
    Store: topLevelAdmin("Store"),
    Delivery: topLevelAdmin("Delivery"),
    Match: topLevelAdmin("Match"),
  },
};
