import {
  Layout,
  Table,
  Pagination,
  PaginationProps,
  Space,
  notification,
  theme,
} from "antd";

import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { MatchTable } from "./type";
import { Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";

const { Content } = Layout;

//상품명, 판매가격, 할인가격, 대표이미지, 등록일, 재고, 총판매량, 상태
//상태는 무엇을 의미하나??

export interface AmountData {
  totalAmount: number;
  totalPaidAmount: number;
  availableAmount: number;
  remainAmount: number;
}
interface GETDATATYPE {
  data: MatchTable[];
  total: number;
}

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<MatchTable[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Match.Main.url(
        1,
        navFilter({ search: value, sort: sort, order: order })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Match.Main.url(
        page,
        navFilter({ search: search, sort: sort, order: order })
      )
    );
    setCurrentPage(page);
  };
  const handleChangeOrder = (value: string) => {
    navigate(
      LPPUrls.Admin.Match.Main.url(
        currentPage,
        navFilter({ search: search, sort: sort, order: order })
      )
    );
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETDATATYPE>(
      urlFilter({
        url: `/seller/match`,
        page: currentPage,
        perPage: 25,
        search: search,
        sort: sort,
        order: order,
      })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        api.error({
          message: "매칭목록을 불러오지 못했습니다.",
        });
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, search, order, sort]);

  return (
    <>
      {contextHolder}
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>매칭 관리</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={columns}
          title={() => (
            <Space className="flex items-end justify-between"></Space>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Match.Detail.url(record.id), {
                  state: { match: record },
                });
              }, // click row
            };
          }}
        />
      </Content>
    </>
  );
};

const columns: ColumnsType<MatchTable> = [
  {
    title: "MD",
    dataIndex: "mdNickName",
  },

  {
    title: "Creator",
    dataIndex: "nickName",
  },
];
