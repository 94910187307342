import {
  Layout,
  Table,
  Pagination,
  PaginationProps,
  Space,
  notification,
  Statistic,
  Button,
  Card,
  Modal,
} from "antd";

import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { TableCalculate } from "./type";
import { timeConverter } from "../../../utils/dateTimeConveter";

const { Content } = Layout;

//상품명, 판매가격, 할인가격, 대표이미지, 등록일, 재고, 총판매량, 상태
//상태는 무엇을 의미하나??

export interface AmountData {
  totalAmount: number;
  totalPaidAmount: number;
  availableAmount: number;
  remainAmount: number;
}
interface GETDATATYPE {
  amountData: AmountData;
  data: TableCalculate[];
  total: number;
}

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [amountData, setAmountData] = useState<AmountData>();
  const [data, setData] = useState<TableCalculate[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [credit, setCredit] = useState<{
    name: string;
    bank: string;
    credit: string;
  }>();

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    onClickSettlementCalc();
  };
  const handleCancel = () => {
    setOpen(false);
  };
  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETDATATYPE>(
      urlFilter({
        url: `/seller/settlement`,
        page: currentPage,
        perPage: 25,
        search: search,
        sort: sort,
        order: order,
      })
    )
      .then((res) => {
        setAmountData(res.data.amountData);
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        api.error({
          message: "정산목록을 불러오지 못했습니다.",
        });
      })
      .finally(() => setLoading(false));

    LPPAXIOS.get("/seller/settlement/credit")
      .then((resolve) => {
        console.log(resolve.data);
        setCredit(resolve.data);
      })
      .catch((error) => {
        api.error({
          message: error.response?.data.message ?? "계좌정보 조회 실패",
        });
      });
  }, [currentPage, api, search, order, sort]);

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Calculate.Main.url(
        1,
        navFilter({ search: value, sort: sort, order: order })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Calculate.Main.url(
        page,
        navFilter({ search: search, sort: sort, order: order })
      )
    );
    setCurrentPage(page);
  };
  const handleChangeOrder = (value: string) => {
    navigate(
      LPPUrls.Admin.Calculate.Main.url(
        currentPage,
        navFilter({ search: search, sort: sort, order: order })
      )
    );
  };

  const onClickSettlementCalc = () => {
    LPPAXIOS.post("/seller/settlement/calc")
      .then((resolve) => {
        api.success({ message: "정산요청이 완료되었습니다." });
        setAmountData((prev) => {
          return { ...prev!, availableAmount: 0 };
        });
      })
      .catch((error) => {
        api.error({ message: error.response.data.message ?? "정산 요청 실패" });
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="정산을 요청하기 전 계좌정보를 확인하세요."
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okButtonProps={{ type: "primary" }}
        okText="진행하기"
      >
        <p>정산 요청 후에는 정산 취소가 불가능합니다.</p>
        <p>계좌정보가 맞나요?</p>
        <p>
          예금주: <strong>{credit?.name}</strong>
        </p>
        <p>
          은행명: <strong>{credit?.bank}</strong>
        </p>
        <p>
          계좌번호: <strong>{credit?.credit}</strong>
        </p>
        <p>정산 받을 계좌정보가가 맞으면 진행하기를 눌러주세요.</p>
      </Modal>
      <Content className="m-6 bg-white">
        <Table
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={columns}
          scroll={{
            x: 1700,
          }}
          title={() => (
            <Space className="flex items-end justify-between">
              <Space>
                <Card bordered={false}>
                  <Statistic
                    title="누적 정산 금액"
                    value={amountData?.totalAmount}
                    loading={loading}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic
                    title="이번달 정산 신청 가능 금액"
                    value={amountData?.availableAmount}
                    loading={loading}
                  />
                </Card>
              </Space>

              <Button
                disabled={(amountData?.availableAmount || 0) < 10000}
                type="primary"
                className="rounded-none"
                onClick={showModal}
              >
                정산요청
              </Button>
            </Space>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          // onRow={(record, _) => {
          //   return {
          //     onClick: () => {
          //       navigate(LPPUrls.Admin.Calculate.Detail.url(record.id));
          //     }, // click row
          //   };
          // }}
        />
      </Content>
    </>
  );
};

const columns: ColumnsType<TableCalculate> = [
  {
    title: "주문번호",
    dataIndex: "orderNumber",
    key: "orderNumber",
    width: "15%",
    fixed: true,
  },

  {
    title: "정산금액",
    dataIndex: "amount",
    key: "amount",
    fixed: true,
    render: (amount: number) => (amount || 0).toLocaleString(),
  },
  {
    title: "정산 비율",
    dataIndex: "rate",
    key: "rate",
    fixed: true,
  },

  {
    title: "상품명",
    dataIndex: "productName",
    key: "productName",
    width: "15%",
  },
  {
    title: "주문 수량",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "옵션",
    dataIndex: "optionName",
    key: "optionName",
  },
  {
    title: "상품가격",
    dataIndex: "originPrice",
    key: "originPrice",
    render: (originPrice: number) => (originPrice || 0).toLocaleString(),
  },
  {
    title: "할인",
    dataIndex: "discountPrice",
    key: "discountPrice",
    render: (discountPrice: number) => (discountPrice || 0).toLocaleString(),
  },
  {
    title: "옵션가격",
    dataIndex: "optionalPrice",
    key: "optionalPrice",
    render: (optionalPrice: number) => (optionalPrice || 0).toLocaleString(),
  },

  {
    title: "정산 발행일",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: string) => timeConverter(createdAt),
  },
];
