import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Divider,
  Image,
  Space,
  Result,
  Button,
  Descriptions,
  Card,
  Skeleton,
  Alert,
  Form,
  Input,
  Modal,
  Select,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { AxiosError } from "axios";
import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
import { DETAILORDER, DetailOrderList, TABLEORDER } from "./type";

import { timeConverter } from "../../../utils/timeConverter";
import { useForm } from "antd/es/form/Form";

const { Header } = Layout;
const { Title } = Typography;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 24 },
};
export const DetailPage = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const order = state?.order as TABLEORDER;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [data, setData] = useState<DETAILORDER>();
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [form] = useForm();

  const [api, contextHolder] = useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleOk = () => {
    // Form 제출을 트리거합니다.
    form.submit();
  };

  const onFinish = (values: {
    invoiceNumber: string;
    deliveryCompany: string;
  }) => {
    // 폼 제출 로직 (예: 서버에 데이터 전송)

    console.log("result", {
      invoiceNumber: Number(values.invoiceNumber),
      deliveryCompany: values.deliveryCompany,
    });
    LPPAXIOS.put(`/seller/settlement/invoice/${id}`, {
      invoiceNumber: Number(values.invoiceNumber),
      deliveryCompany: values.deliveryCompany,
    })
      .then((resolve) => {
        console.log(resolve);
        setData((prev) => {
          return { ...prev!, invoiceNumber: Number(values.invoiceNumber) };
        });
        navigate(LPPUrls.Admin.SalesManagement.Main.url());
      })
      .finally(() => {
        setShowForm(false); // 모달 닫기
      });
  };

  const onClickShowInput = () => {
    setShowForm(true);
  };

  const onClickBack = () => {
    navigate(LPPUrls.Admin.SalesManagement.Main.url());
  };

  useEffect(() => {
    LPPAXIOS.get<DETAILORDER>(`/seller/settlement/purchases/${id}`)
      .then((resolve) => {
        console.log(resolve.data);
        setData(resolve.data);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, api]);

  if (!order) {
    return (
      <Result
        status={404}
        subTitle="해당 주문 내역을 찾을 수 없습니다."
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (error !== null && error.response.status === 404)
    return (
      <Result
        title={error?.message}
        subTitle="해당 주문 내역을 찾을 수 없습니다."
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={error?.message}
        subTitle="해당 주문 내역을 찾을 수 없습니다."
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>주문 상세</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.SalesManagement.Main.url()}>
              주문 통합 관리
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        {loading ? (
          <Skeleton active={loading} />
        ) : (
          <>
            <Typography.Title level={4}>주문상세 내역</Typography.Title>
            {data?.invoiceNumber ? (
              <Descriptions column={1} bordered>
                <Descriptions.Item label="배송사">
                  {order.deliveryCompany}
                </Descriptions.Item>
                <Descriptions.Item label="운송장 번호">
                  {data.invoiceNumber}
                </Descriptions.Item>
              </Descriptions>
            ) : data?.orderStatus === "결제완료" ? (
              <Alert
                type="warning"
                message="택배사와 운송장 번호를 등록하세요."
                showIcon
                action={
                  <Button
                    disabled={data?.orderStatus !== "결제완료"}
                    onClick={onClickShowInput}
                  >
                    송장번호 등록
                  </Button>
                }
              />
            ) : (
              <Alert
                type="info"
                message="결제완료된 주문만 송장번호를 입력할 수 있습니다."
                showIcon
              />
            )}

            <Modal
              title="택배운송장 등록"
              open={showForm}
              onCancel={() => setShowForm(false)}
              onOk={handleOk}
            >
              <Form {...layout} form={form} onFinish={onFinish}>
                <Form.Item
                  label="택배사 선택"
                  rules={[
                    { required: true, message: "택배사를를 선택하세요." },
                  ]}
                  name="deliveryCompany"
                >
                  {/* <Select
                    options={fulfillmentCompany}
                    placeholder="배송사를 선택하세요."
                  /> */}
                  {/* <Input maxLength={30} placeholder="택배사를 입력하세요." /> */}
                  <Select
                    showSearch
                    placeholder="배송사를 선택하세요."
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toUpperCase()
                        .localeCompare((optionB?.label ?? "").toUpperCase())
                    }
                    options={[
                      {
                        value: "우체국택배",
                        label: "우체국택배",
                      },
                      {
                        value: "CJ대한통운",
                        label: "CJ대한통운",
                      },
                      {
                        value: "한진택배",
                        label: "한진택배",
                      },
                      {
                        value: "로젠택배",
                        label: "로젠택배",
                      },
                      {
                        value: "롯데택배",
                        label: "롯데택배",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="invoiceNumber"
                  label="송장번호"
                  rules={[
                    { required: true, message: "송장번호를 입력하세요." },
                    {
                      pattern: new RegExp(/^\d+$/),
                      message: "숫자만 입력 가능합니다.",
                    },
                  ]}
                >
                  <Input maxLength={30} placeholder="송장번호를 입력하세요" />
                </Form.Item>
              </Form>
            </Modal>

            <Descriptions column={1} bordered>
              <Descriptions.Item label="주문일자">
                {data?.createdAt ? timeConverter(data.createdAt) : ""}
              </Descriptions.Item>
              <Descriptions.Item label="주문번호">
                {data?.orderNumber}
              </Descriptions.Item>
              <Descriptions.Item label="결제방법">
                {data?.paymentMethod}
              </Descriptions.Item>
            </Descriptions>

            <Card
              title={
                <Space className="flex justify-between w-full">
                  <span>상품정보</span>
                  <span>{data?.deliveryStatus}</span>
                  {/* <Button onClick={onClickDeliveryStatus}>
                {data?.deliveryStatus}
              </Button> */}
                </Space>
              }
            >
              <>
                {data?.orderList
                  ? data.orderList.map((orderItem, idx) => {
                      return (
                        <Space
                          key={idx}
                          className="flex items-start justify-start w-full"
                        >
                          <Image
                            preview={false}
                            width={100}
                            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${orderItem.productRepresentativeImage}`}
                          />
                          <Space className="flex flex-col items-start justify-start w-full">
                            <Typography.Title level={4}>
                              {orderItem.productName}
                            </Typography.Title>
                            <Typography.Text type="secondary">
                              {orderItem.optionalProductInfo.color} /{" "}
                              {orderItem.optionalProductInfo.size}
                            </Typography.Text>
                            <Typography.Text>
                              {orderItem.originPrice +
                                (orderItem.optionalProductInfo?.optionalPrice ||
                                  0) -
                                (orderItem.discountPrice || 0)}
                              원 / 수량 {orderItem.quantity}개
                            </Typography.Text>
                          </Space>
                        </Space>
                      );
                    })
                  : null}
              </>
              <Divider />
            </Card>
            <Descriptions title="구매자 정보" column={1} bordered>
              <Descriptions.Item label="주문자">
                {data?.senderName}
              </Descriptions.Item>
              <Descriptions.Item label="연락처">
                {data?.senderPhone}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title="배송지 정보" column={1} bordered>
              <Descriptions.Item label="수령인">
                {data?.recipientName}
              </Descriptions.Item>
              <Descriptions.Item label="연락처">
                {data?.recipientPhone}
              </Descriptions.Item>
              <Descriptions.Item label="배송지">
                {data?.recipientAddress}
              </Descriptions.Item>
              <Descriptions.Item label="배송메모">
                {data?.receiving_location} / {data?.way_to_enter}
              </Descriptions.Item>
            </Descriptions>

            <Title level={4}>주문 금액 상세</Title>
            <Card>
              <Space className="flex justify-between w-full">
                <Typography.Text strong>주문금액</Typography.Text>
                <Typography.Text strong>
                  {(
                    sumOrderPrice(data?.orderList || []) +
                    (data?.deliveryFee || 0)
                  ).toLocaleString()}
                  원
                </Typography.Text>
              </Space>
              <Space className="flex justify-between w-full">
                <Typography.Text type="secondary">ㄴ상품금액</Typography.Text>
                <Typography.Text type="secondary">
                  {sumOrderPrice(data?.orderList || []).toLocaleString()}원
                </Typography.Text>
              </Space>
              <Space className="flex justify-between w-full">
                <Typography.Text type="secondary">ㄴ배송비</Typography.Text>
                <Typography.Text type="secondary">
                  {(data?.deliveryFee || 0).toLocaleString()}원
                </Typography.Text>
              </Space>
              <Divider />
              <Space className="flex justify-between w-full">
                <Typography.Text strong>할인금액</Typography.Text>
                <Typography.Text strong>
                  -{(data?.totalDiscount || 0).toLocaleString()}원
                </Typography.Text>
              </Space>
              <Space className="flex justify-between w-full">
                <Typography.Text type="secondary">상품 할인</Typography.Text>
                <Typography.Text type="secondary">
                  {(data?.totalDiscount || 0).toLocaleString()}원
                </Typography.Text>
              </Space>
              <Divider />
              <Space className="flex justify-between w-full">
                <Typography.Text>총 주문금액</Typography.Text>
                <Typography.Text strong>
                  {(data?.totalPayment || 0).toLocaleString()}원
                </Typography.Text>
              </Space>
            </Card>
          </>
        )}
      </HContent>
      {contextHolder}
    </>
  );
};

function sumOrderPrice(value: DetailOrderList[]) {
  return value.reduce((total, item) => {
    // item의 originPrice와 optionalProductInfo의 optionalPrice를 합산
    const itemTotal =
      item.originPrice + (item.optionalProductInfo?.optionalPrice || 0);
    return total + itemTotal;
  }, 0); // 초기값은 0
}
