import { Tabs } from "antd";
import { Content } from "antd/es/layout/layout";

import BrandPage from "./Brand";
import BankPage from "./Bank";

export default function MainPage() {
  return (
    <>
      <Content className="p-2">
        <Tabs
          type="card"
          items={[
            {
              label: `브랜드관`,
              key: "brand",
              children: <BrandPage />,
            },
            {
              label: `계좌정보`,
              key: "bank",
              children: <BankPage />,
            },
          ]}
        />
      </Content>
    </>
  );
}
