import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Divider,
  Image,
  Space,
  Result,
  Button,
  Descriptions,
  Card,
  Skeleton,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { AxiosError } from "axios";
import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
import { DetailCalculate } from "./type";

import { timeConverter } from "../../../utils/timeConverter";

const { Header } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DetailCalculate>();
  const [error, setError] = useState<any | AxiosError | null>(null);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onClickDeliveryStatus = () => {};
  const onClickBack = () => {
    navigate(LPPUrls.Admin.Calculate.Main.url());
  };

  useEffect(() => {
    LPPAXIOS.get<DetailCalculate>(`/seller/settlement/${id}`)
      .then((resolve) => {
        console.log(resolve.data);
        setData(resolve.data);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  }, [id]);

  if (error !== null && error.response.status === 404)
    return (
      <Result
        title={error?.message}
        subTitle="해당 정산 내역을 찾을 수 없습니다."
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={error?.message}
        subTitle="해당 정산 내역을 찾을 수 없습니다."
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>정산 내역 상세 보기</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Calculate.Main.url()}>정산 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>정산 내역 상세 보기</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <>
          <Typography.Title level={4}>정산 상세 내역</Typography.Title>
          {loading ? (
            <Skeleton active={loading} />
          ) : (
            <Descriptions title="정산내역" bordered>
              <Descriptions.Item label="주문번호">
                {data?.settlements.orderNumber}
              </Descriptions.Item>
              <Descriptions.Item label="정산금액">
                {data?.settlements.amount}
              </Descriptions.Item>
              <Descriptions.Item label="정산비율">
                {data?.settlements.rate}
              </Descriptions.Item>
              <Descriptions.Item label="정산상태">
                {data?.settlements.status}
              </Descriptions.Item>
            </Descriptions>
          )}
          {loading ? (
            <Skeleton active={loading} />
          ) : (
            <Descriptions column={1} bordered>
              <Descriptions.Item label="주문일자">
                {data?.purchases.createdAt
                  ? timeConverter(data.purchases.createdAt)
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="주문번호">
                {data?.purchases.orderNumber}
              </Descriptions.Item>
              <Descriptions.Item label="결제방법">
                {data?.purchases.paymentMethod}
              </Descriptions.Item>
            </Descriptions>
          )}
          {loading ? (
            <Skeleton active={loading} />
          ) : (
            <Card
              title={
                <Space className="flex justify-between w-full">
                  <span>상품정보</span>
                </Space>
              }
            >
              <Divider />
            </Card>
          )}
          {loading ? (
            <Skeleton active={loading} />
          ) : (
            <Descriptions title="구매자 정보" column={1} bordered>
              <Descriptions.Item label="주문자">
                {data?.purchases.senderName}
              </Descriptions.Item>
              <Descriptions.Item label="연락처">
                {data?.purchases.senderPhone}
              </Descriptions.Item>
            </Descriptions>
          )}
          {loading ? (
            <Skeleton active={loading} />
          ) : (
            <Descriptions title="배송지 정보" column={1} bordered>
              <Descriptions.Item label="수령인">
                {data?.purchases.recipientName}
              </Descriptions.Item>
              <Descriptions.Item label="연락처">
                {data?.purchases.recipientPhone}
              </Descriptions.Item>
              <Descriptions.Item label="배송지">
                {data?.purchases.recipientAddress}
              </Descriptions.Item>
              <Descriptions.Item label="배송메모">
                {data?.purchases.receiving_location} /{" "}
                {data?.purchases.way_to_enter}
              </Descriptions.Item>
            </Descriptions>
          )}
          {loading ? (
            <Skeleton active={loading} />
          ) : (
            <>
              <Title level={4}>주문 금액 상세</Title>
              <Card>
                <Space className="flex justify-between w-full">
                  <Typography.Text strong>주문금액</Typography.Text>
                  <Typography.Text strong>
                    {(data?.purchases.totalPrice || 0).toLocaleString()}원
                  </Typography.Text>
                </Space>
                <Space className="flex justify-between w-full">
                  <Typography.Text type="secondary">ㄴ상품금액</Typography.Text>
                  <Typography.Text type="secondary">
                    {(data?.purchases.totalPrice || []).toLocaleString()}원
                  </Typography.Text>
                </Space>
                <Space className="flex justify-between w-full">
                  <Typography.Text type="secondary">ㄴ배송비</Typography.Text>
                  <Typography.Text type="secondary">
                    {(data?.purchases.deliveryFee || 0).toLocaleString()}원
                  </Typography.Text>
                </Space>
                <Divider />
                <Space className="flex justify-between w-full">
                  <Typography.Text strong>할인금액</Typography.Text>
                  <Typography.Text strong>
                    -{(data?.purchases.totalDiscount || 0).toLocaleString()}원
                  </Typography.Text>
                </Space>
                <Space className="flex justify-between w-full">
                  <Typography.Text type="secondary">상품 할인</Typography.Text>
                  <Typography.Text type="secondary">
                    {(data?.purchases.totalDiscount || 0).toLocaleString()}원
                  </Typography.Text>
                </Space>
                <Divider />
                <Space className="flex justify-between w-full">
                  <Typography.Text>총 주문금액</Typography.Text>
                  <Typography.Text strong>
                    {(data?.purchases.totalPayment || 0).toLocaleString()}원
                  </Typography.Text>
                </Space>
              </Card>
            </>
          )}
        </>
      </HContent>
    </>
  );
};
