import { Input, InputProps } from "antd";

interface CustomInputProps extends InputProps {
  style?: React.CSSProperties;
  placeholder?: string;
  name?: string;
}

const NumberInput: React.FC<CustomInputProps> = ({
  style,
  placeholder,
  name,
  ...restProps
}) => {
  return (
    <Input
      className="rounded-none"
      style={style}
      placeholder={placeholder}
      name={name}
      {...restProps}
      onInput={(e) =>
        (e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, ""))
      }
    />
  );
};

export default NumberInput;
