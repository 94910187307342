import Table from "antd/es/table";
import Typography from "antd/es/typography";
import { ColumnsType } from "antd/es/table/interface";
import CardWidget from "../../../components/shared/CustomCardWidget";
import { TABLEORDER } from "./type";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { notification, Pagination, PaginationProps, Tag } from "antd";
import { timeConverter } from "../../../utils/dateTimeConveter";

interface GETORDERLISTTYPE {
  data: TABLEORDER[];
  total: number;
}
export default function TablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TABLEORDER[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.SalesManagement.Main.url(
        page,
        navFilter({
          search: search,
          sort: sort,
          order: order,
        })
      )
    );
    setCurrentPage(page);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETORDERLISTTYPE>(
      urlFilter({
        url: `/seller/settlement/purchases`,
        page: currentPage,
        perPage: 25,
        search: search,
        sort: sort,
        order: order,
      })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        api.error({
          message: "주문목록을 불러오지 못했습니다.",
        });
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, search, order, sort]);

  return (
    <div className="p-2">
      <CardWidget
        title={
          <Typography>
            <Typography.Title level={5}>
              목록(총 <span className=" text-emerald-400">{total}</span>
              개)
            </Typography.Title>
          </Typography>
        }
      >
        <Table
          pagination={false}
          loading={loading}
          rowClassName={() => "cursor-pointer"}
          rowKey={(render) => render.id}
          columns={columns}
          dataSource={data}
          // scroll={{ x: 2000 }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.SalesManagement.Detail.url(record.id), {
                  state: { order: record },
                });
              }, // click row
            };
          }}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </CardWidget>
    </div>
  );
}
const columns: ColumnsType<TABLEORDER> = [
  {
    title: "주문번호",
    dataIndex: "orderNumber",
    key: "orderNumber",
  },
  {
    title: "택배사",
    dataIndex: "deliveryCompany",
    key: "deliveryCompany",
    render: (deliveryCompany) => {
      if (deliveryCompany !== "null") {
        return <p>{deliveryCompany}</p>;
      } else {
        return <Tag color="red">미입력</Tag>;
      }
    },
  },
  {
    title: "송장번호",
    dataIndex: "invoiceNumber",
    key: "invoiceNumber",
    render: (invoiceNumber: any) => {
      if (invoiceNumber) {
        return <p>{invoiceNumber}</p>;
      } else {
        return <Tag color="red">미입력</Tag>;
      }
    },
  },

  { title: "결제 금액", dataIndex: "totalPayment", key: "totalPayment" },
  // { title: "배송비", dataIndex: "deliveryFee", key: "deliveryFee" },
  { title: "결제 방식", dataIndex: "paymentMethod", key: "paymentMethod" },

  {
    title: "주문 상태",
    dataIndex: "orderStatus",
    key: "orderStatus",
    render: (status: string) => {
      return <Tag>{status}</Tag>;
    },
  },
  // {
  //   title: "배송 상태",
  //   dataIndex: "deliveryStatus",
  //   key: "deliveryStatus",
  //   render: (status: string) => {
  //     return <Tag>{status}</Tag>;
  //   },
  // },
  {
    title: "주문시각",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: string) => timeConverter(createdAt),
  },
];
