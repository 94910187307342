export function setItem(name: string, value: any) {
  localStorage.setItem(name, JSON.stringify(value));
}
export function getItem(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) return JSON.parse(localData);
  return null;
}
export function removeItem(name: string) {
  localStorage.removeItem(name);
  return null;
}

export const currentSeller = "kr.aizi.seller.state_token";
