import { useQuery } from "@tanstack/react-query";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LPPAXIOS } from "../../framework/api/core.api";
import { LPPUrls } from "../../LPPUrls";

import { CalculateRoot } from "../../pages/AdminPages/Calculate/Root";
import { DeliveryRoot } from "../../pages/AdminPages/Delivery/Root";
import { MatchRoot } from "../../pages/AdminPages/Match/Root";
import { MyPageRoot } from "../../pages/AdminPages/MyPage/Root";
import { NoticeRoot } from "../../pages/AdminPages/Notice/Root";
// import { OrdersRoot } from "../../pages/AdminPages/Orders/Root";
import { ProductsRoot } from "../../pages/AdminPages/Products/Root";
import { ReviewRoot } from "../../pages/AdminPages/Review/Root";

import SalesManagementRoot from "../../pages/AdminPages/SalesManagement/Root";
import StoreRoot from "../../pages/AdminPages/Store/Root";
import TermRoot from "../../pages/AdminPages/Term/Root";
import { currentSeller, getItem } from "../../utils/localStorage";

import { LPPAdminCommonLayout } from "../Layout/LPPAdminCommonLayout";

interface LoginSessionType {
  // id: number;
  // userId: number;
  role: string;
  nickName: string;
  // phone: string;
  // email: string;
  // password: string;
  // salt: string;
  // createdAt: string;
  // updatedAt: string;
  // deletedAt: any;
  // isDeleted: number;
  // recommendedId: any;
  // platform: string;
  // memo: string;
  // type: string;
}

export const LPPAdminRoutes = () => {
  const navigate = useNavigate();
  useQuery({
    queryKey: ["useSession"],
    queryFn: async () => {
      try {
        const res = await LPPAXIOS.get<LoginSessionType>("/seller/auth");
        if (res.status === 200) {
          const { role, nickName } = res.data;
          localStorage.setItem(
            currentSeller,
            JSON.stringify({ role, nickName })
          );
          return { role, nickName };
        }
        return null;
      } catch (error) {
        localStorage.removeItem(currentSeller);
        return navigate(LPPUrls.Auth.Root.pathName, { replace: true });
      }
    },
    refetchOnWindowFocus: false,
    refetchInterval: 6600 * 1000,
  });
  let localData = getItem(currentSeller);

  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }
  // const data: ResponsePostAuth = JSON.parse(localData);

  return (
    <LPPAdminCommonLayout>
      <Routes>
        {/* <Route
          path={LPPUrls.Admin.MyPage.Main.pathName}
          element={<MyPageRoot />}
        /> */}

        {/* 스토어 관리 */}
        <Route
          path={LPPUrls.Admin.Store.Main.pathName}
          element={<StoreRoot />}
        />

        {/* 상품 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Products.Main.pathName}
          element={<ProductsRoot />}
        />

        {/* 판매관리 라우터 */}
        <Route
          path={LPPUrls.Admin.SalesManagement.Main.pathName}
          element={<SalesManagementRoot />}
        />

        {/* 주문 페이지 라우터 */}
        {/* <Route
          path={LPPUrls.Admin.Orders.Main.pathName}
          element={<OrdersRoot />}
        /> */}
        {/* 발주(주문)확인/발송관리 */}
        {/* <Route
          path={LPPUrls.Admin.Delivery.Main.pathName}
          element={<DeliveryRoot />}
        /> */}

        {/* 공지 사항 페이지 라우터 */}
        {/* <Route
          path={LPPUrls.Admin.Notice.Main.pathName}
          element={<NoticeRoot />}
        /> */}
        {/* 리뷰조회 */}
        <Route
          path={LPPUrls.Admin.Review.Main.pathName}
          element={<ReviewRoot />}
        />
        {/* 정산관리 */}
        <Route
          path={LPPUrls.Admin.Calculate.Main.pathName}
          element={<CalculateRoot />}
        />
        {/* 매칭관리 */}
        <Route
          path={LPPUrls.Admin.Match.Main.pathName}
          element={<MatchRoot />}
        />

        {/* 이용약관 확인 페이지 */}
        {/* <Route path={LPPUrls.Admin.Term.Main.pathName} element={<TermRoot />} /> */}

        <Route
          path={"*"}
          element={<Navigate to={LPPUrls.Admin.Calculate.Main.url()} />}
        />
      </Routes>
    </LPPAdminCommonLayout>
  );
};
