import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { RuleObject } from "antd/lib/form";

import {
  MailOutlined,
  PhoneOutlined,
  LinkOutlined,
  UnlockOutlined,
  UserOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { LPPUrls } from "../LPPUrls";

import { onPressOnlyNumber } from "../utils/inputOnlyNumber";
import TextArea from "antd/es/input/TextArea";
import Password from "antd/es/input/Password";
import { LPPAXIOS } from "../framework/api/core.api";
import useNotification from "antd/es/notification/useNotification";
import { useState } from "react";
import Search from "antd/es/input/Search";
import Dragger from "antd/es/upload/Dragger";
import { handleUploadCompMultiFiles } from "../utils/uploadImgToS3";

const props: UploadProps = {
  name: "file",
  multiple: false,
  maxCount: 1,
  beforeUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";

    const isMp4 = file.type === "video/mp4";
    if (!isJpgOrPng && !isMp4) {
      message.error("이미지 및 PDF 파일만 업로드 가능합니다.");
      return Upload.LIST_IGNORE; // 이 파일을 업로드 목록에서 제외합니다.
    }

    return true; // 파일 형식이 조건에 맞으면 업로드를 계속 진행합니다.
  },
  customRequest({ file, onSuccess }) {
    const res = "Ok";
    onSuccess!(res);
  },
};

interface FormValues {
  nickName: string;
  platform: string;
  appNickName: string;
  phone: string;
  email: string;
  password: string;
  confirm_password: string;
  recommendedId?: string;
  memo?: string;
  sellerRegistration: string;
  sellerFile: any;
  sellerEC: any;
}
interface RecommedMD {
  id: number;
  role: string;
  nickName: string;
}
export const FindPasswordPage = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [currentMDObj, setCurrentMDObj] = useState<RecommedMD | null>(null);
  const [MDObj, setMDObj] = useState<RecommedMD | null>(null);

  const [api, contextHolder] = useNotification();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<UploadFile[]>([]);
  const [sellerECFile, setSellerECFile] = useState<UploadFile[]>([]);
  const onFinish = async (value: FormValues) => {
    try {
      setLoading(true);
      const sellerRegistration: string[] =
        (await handleUploadCompMultiFiles(file, "sellerRegistration")) ?? [];
      const sellerEcommerceCertification: string[] =
        (await handleUploadCompMultiFiles(sellerECFile, "sellerEC")) ?? [];
      if (sellerRegistration.length) {
        const { confirm_password, sellerFile, sellerEC, ...info } = value;
        console.log("info::");
        console.log({
          ...info,
          sellerFile: sellerRegistration[0],
          sellerEC: sellerEcommerceCertification[0],
        });

        const response = await LPPAXIOS.post(`/seller/seller`, {
          ...info,
          recommend: currentMDObj?.nickName,
          sellerFile: sellerRegistration[0],
          sellerEC: sellerEcommerceCertification[0],
        }).then((resolve) => {
          api.success({
            message: "셀러가입 신청이 완료되었습니다.",
          });
          setTimeout(() => {
            navigate(LPPUrls.Auth.Login.Main.pathName);
          }, 600);
        });
      }
    } catch (error: any) {
      console.log(error.response.status);
      if (error.response.status === 409) {
        api.error({
          message: "해당계정으로 이미 가입신청이 되어있습니다.",
        });
      } else if (error.response.status === 419) {
        api.error({
          message: "이미 존재하는 셀러 닉네임입니다",
        });
      } else if (error.response.status === 420) {
        api.error({
          message: "이미 존재하는 이메일입니다",
        });
      } else if (error.response.status === 421) {
        api.error({
          message:
            "입력하신 앱닉네임이 존재하지 않습니다. \n 앱에 먼저 가입한 후 해당 아이디를 입력해 주세요",
        });
      } else if (error.response.status === 422) {
        api.error({
          message: "해당 계정은 크리에이터 또는 MD로 가입되어 있습니다.",
        });
      } else if (error.response.status === 423) {
        api.error({
          message: "해당계정으로 이미 가입신청이 되어있습니다.",
        });
      } else {
        api.error({
          message: error.response.data.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const validatePasswords: RuleObject = {
    validator: async (_: RuleObject, value: string | number | undefined) => {
      if (!value || value === form.getFieldValue("password")) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("비밀번호가 일치하지 않습니다."));
    },
  };

  const handleSearchMD = (value: string) => {
    LPPAXIOS.post<RecommedMD>(`/admin/users/nickName`, {
      nickName: value,
    })
      .then((resolve) => {
        switch (resolve.data.role) {
          case "seller":
            api.warning({
              message: "해당 계정은 셀러 계정입니다.",
              description: "MD의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
          case "md":
            setMDObj({ ...resolve.data, nickName: value });

            break;
          case "creator":
            api.warning({
              message: "해당 계정은 크리에이터 계정입니다.",
              description: "MD의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
          default:
            api.warning({
              message: "존재하지 않는 계정입니다.",
              description: "MD의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
        }
      })
      .catch((error) => {
        api.error({
          message: "찾을 수 없는 아이디입니다.",
          description: "아이디를 올바르게 입력하세요.",
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
    setMDObj(null);
  };
  const handleOk = () => {
    if (MDObj) {
      setCurrentMDObj(MDObj);
    }
    setMDObj(null);
    setOpen(false);
  };
  const showModal = () => {
    setOpen(true);
  };
  const [form] = Form.useForm<FormValues>();

  const onChangeMusicFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setFile(newupdatedFileList);
  };
  const onChangeSellerECFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setSellerECFile(newupdatedFileList);
  };

  const validateFiles = (rule: any, value: any) => {
    if (file.length === 0) {
      return Promise.reject(new Error("사업자등록증 등록은 필수입니다."));
    }
    return Promise.resolve();
  };
  const validateECFiles = (rule: any, value: any) => {
    if (file.length === 0) {
      return Promise.reject(new Error("통신판매 신고증 등록은 필수입니다."));
    }
    return Promise.resolve();
  };
  return (
    <>
      {contextHolder}

      <div className="relative flex justify-center w-screen h-screen overflow-y-scroll bg-slate-600">
        <div className="px-1 py-2 w-fit h-fit">
          <Card title={"AZ Seller 가입 신청서"}>
            <Form
              layout="vertical"
              // name="normal_login"
              // className="w-[450px]"
              style={{ minWidth: 600 }}
              onFinish={onFinish}
              form={form}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label={"Seller 닉네임"}
                    name="nickName"
                    rules={[
                      {
                        required: true,
                        message:
                          "셀러 페이지에서 사용할 닉네임 입력은 필수입니다.",
                      },
                    ]}
                    extra="셀러 페이지에서 사용할 닉네임입니다."
                  >
                    <Input
                      className="rounded-none"
                      prefix={<UserOutlined rev={undefined} />}
                      placeholder="셀러 페이지에서 사용할 닉네임"
                      // onChange={handleChange1}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={"AZ APP 닉네임"}
                    name="appNickName"
                    rules={[
                      {
                        required: true,
                        message:
                          "셀러 페이지에서 사용할 닉네임 입력은 필수입니다.",
                      },
                    ]}
                    extra="현재 앱에서 사용중인 닉네임을 정확히 입력하세요."
                  >
                    <Input
                      className="rounded-none"
                      prefix={<UserOutlined rev={undefined} />}
                      placeholder="앱에서 사용하는 본인의 닉네임"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={"이메일"}
                name="email"
                extra="해당 이메일이 셀러 페이지 로그인 아이디입니다."
                rules={[{ required: true, message: "이메일을 입력하세요." }]}
              >
                <Input
                  type="email"
                  autoComplete="off"
                  className="rounded-none"
                  prefix={<MailOutlined rev={undefined} />}
                  placeholder="az.hellow@aizi.com"
                />
              </Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label={"비밀번호"}
                    name="password"
                    extra="특수문자를 포함한 알파벳과 숫자를 조합한 8자 이상"
                    rules={[
                      {
                        required: true,
                        message: "비밀번호 입력은 필수입니다.",
                      },
                      {
                        pattern: new RegExp(
                          "^(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9\\s]).{8,30}$"
                        ),
                        message:
                          "비밀번호는 길이 8자 이상이며, 소문자, 숫자, 특수문자를 각각 최소 하나 이상 포함해야 합니다. 대문자와 공백은 사용할 수 없습니다.",
                      },
                    ]}
                  >
                    <Password
                      autoComplete="new-password"
                      // autoComplete="off"
                      className="rounded-none"
                      prefix={<UnlockOutlined rev={undefined} />}
                      placeholder="비밀번호를 입력해주세요"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="비밀번호 확인"
                    name="confirm_password"
                    rules={[
                      {
                        validator(rule, value, callback) {
                          if (form.getFieldValue("password") === value) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject();
                          }
                        },
                        message: "비밀번호가 일치하지 않습니다.",
                      },
                    ]}
                  >
                    <Password
                      autoComplete="new-password"
                      className="rounded-none"
                      prefix={<UnlockOutlined rev={undefined} />}
                      placeholder="비밀번호를 입력해주세요 (8자리 이상)"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="운영중인 홈페이지"
                name="platform"
                rules={[
                  {
                    required: true,
                    message:
                      "현재 운영중인 쇼핑몰 또는 플랫폼 주소를 입력해주세요.",
                  },
                ]}
              >
                <Input
                  className="rounded-none"
                  prefix={<LinkOutlined rev={undefined} />}
                  placeholder="플랫폼 주소 or 운영중인 쇼핑몰 주소"
                />
              </Form.Item>
              <Form.Item
                label={"핸드폰"}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "핸드폰 정보 입력은 필수입니다.",
                  },
                  {
                    pattern: new RegExp(/^\d+$/),
                    message: "숫자만 입력 가능합니다.",
                  },
                ]}
              >
                <Input
                  type="tel"
                  className="rounded-none"
                  maxLength={11}
                  prefix={<PhoneOutlined rev={undefined} />}
                  placeholder="01012345678 (숫자만 입력)"
                  onChange={onPressOnlyNumber}
                />
              </Form.Item>
              <Form.Item
                label={"사업자등록번호"}
                name="sellerRegistration"
                rules={[
                  {
                    required: true,
                    message: "사업자등록번호 정보 입력은 필수입니다.",
                  },
                  {
                    pattern: new RegExp(/^\d+$/),
                    message: "숫자만 입력 가능합니다.",
                  },
                ]}
              >
                <Input
                  type="text"
                  className="rounded-none"
                  maxLength={100}
                  // prefix={<PhoneOutlined rev={undefined} />}
                  placeholder="사업자등록번호"
                  onChange={onPressOnlyNumber}
                />
              </Form.Item>
              <Form.Item
                label={"사업자등록증"}
                name="sellerFile"
                rules={[{ validator: validateFiles }]}
              >
                <Dragger
                  maxCount={1}
                  multiple={false}
                  fileList={file}
                  onChange={onChangeMusicFile}
                  {...props}
                  accept="image/jpg,image/png,image/jpeg,application/pdf"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined rev={undefined} />
                  </p>
                  <p className="ant-upload-text">
                    파일 형식은 jpg, png, pdf만 가능합니다.
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item
                label={"통신판매신고증"}
                name="sellerEC"
                rules={[{ validator: validateECFiles }]}
              >
                <Dragger
                  maxCount={1}
                  multiple={false}
                  fileList={sellerECFile}
                  onChange={onChangeSellerECFile}
                  {...props}
                  accept="image/jpg,image/png,image/jpeg,application/pdf"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined rev={undefined} />
                  </p>
                  <p className="ant-upload-text">
                    파일 형식은 jpg, png, pdf만 가능합니다.
                  </p>
                </Dragger>
              </Form.Item>

              <Button block onClick={showModal} className="my-2 rounded-none">
                추천 MD 추가하기
              </Button>
              {currentMDObj ? (
                <Alert
                  className="rounded-none"
                  type="success"
                  message={`추천 MD의 앱닉네임은 ${currentMDObj.nickName} 입니다.`}
                  action={
                    <Button onClick={() => setCurrentMDObj(null)}>취소</Button>
                  }
                />
              ) : (
                <Alert
                  className="rounded-none"
                  type="warning"
                  message="추천할 엠디가 없으신가요?"
                />
              )}

              <Form.Item label="요청설명" name="memo">
                <TextArea
                  maxLength={500}
                  className="rounded-none"
                  placeholder="추가적으로 전달하실 사항이 있다면 작성해주세요."
                />
              </Form.Item>

              <Form.Item>
                <Link
                  className="float-right text-blue-400"
                  to={LPPUrls.Auth.Login.Main.url}
                >
                  로그인 페이지
                </Link>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="rounded-none"
                  loading={loading}
                >
                  가입 신청
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <Modal
          open={open}
          onOk={handleOk}
          onCancel={handleClose}
          okButtonProps={{ type: "primary", disabled: MDObj ? false : true }}
          okText="추가하기"
          title="추천 MD 앱 닉네임 검색"
          destroyOnClose
        >
          <Search
            onSearch={handleSearchMD}
            className="my-2"
            placeholder="MD의 앱 닉네임을 정확하게 입력하세요."
          />

          {MDObj ? (
            <Alert type="success" message={MDObj.nickName} />
          ) : (
            <Alert type="warning" message="추천인 MD의 계정이 필요합니다." />
          )}
        </Modal>
      </div>
    </>
  );
};
