import type { SelectProps } from "antd/es/select";
import { Select, Spin } from "antd";
import { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { HashTag } from "./types";
export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  value?: ValueType[]; // <- 여기에 추가
}

export function DebounceSelect<
  ValueType extends {
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 300,
  value, // <- 여기에 추가
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      if (!value.trim()) {
        // 빈 문자열인 경우 검색하지 않음
        return;
      }

      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      const newOptions = await fetchOptions(value);

      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }

      // 검색된 결과가 없으면, 입력한 텍스트를 옵션으로 추가
      if (newOptions.length === 0) {
        setOptions([{ label: value, value: Date.now() } as ValueType]);
      } else {
        setOptions(newOptions);
      }

      setFetching(false);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      value={value} // <- 여기에 추가
    />
  );
}

export async function fetchUserList(username: string): Promise<HashTag[]> {
  const response = await LPPAXIOS.get(
    `/seller/hashtag/recommend?word=${username}`
  ).catch((error) => {
    return { data: { data: [] } };
  });
  const data = response.data.data;

  // Map the results to the format { label, value }
  return data.map((user: any) => ({
    label: user.tag,
    value: user.id,
  }));
}
