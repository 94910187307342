import { BrowserRouter } from "react-router-dom";
import { LPPRootRoutes } from "./components/routes/LPPRootRoutes";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";

import koKR from "antd/locale/ko_KR";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

dayjs.locale("ko");

const queryClient = new QueryClient();
const locale = koKR;

function App() {
  return (
    <React.StrictMode>
      <ConfigProvider locale={locale}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <LPPRootRoutes />
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
      </ConfigProvider>
    </React.StrictMode>
  );
}

export default App;
